import React,{useEffect} from "react";
import Styled from "styled-components";

export default function Mcc() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <DIV>
      <BOX>
        <h1>MOOT COURT COMMITTEE</h1>

        <COLOR>
          <TOP>
            <p>
              MCC coordinates the moot court activities of students of SLS.
              Every year internal moot court competition is organised to provide
              training to LLB students in moot court and for preparing a rank
              list of speakers and researchers for sending them for external
              moot court competitions. Training sessions on Moot courts are
              conducted by MCC with the support of our Alumni. Dr. A. T. Markose
              Memorial National Moot Court Competition is a flagship event
              annually organised by SLS MCC. Dr. A. T. Markose Memorial Moot
              Court Competition is a National Level Moot Court Competition
              conducted every year by the School of Legal Studies (SLS), CUSAT
              in the loving memory of their founder, Dr. A. T. Markose, a
              renowned academician and scholar.
            </p>
            <p>
              School of Legal Studies has hosted five successful editions of
              this Competition editions in 2012, 2013, 2014, 2015 and 2017. The
              2020 edition of the Competition was jointly organized by School of
              Legal Studies with Surana & Surana International Attorneys (SSIA),
              a law firm engaged in providing professional, legal and consulting
              services nationally and internationally and a pioneer in
              organizing International/National Level Moot Court Competitions
              incorporating best practices. The theme of the Competition was
              Technology law which was chosen with an intention to create
              awareness on the emerging technology law in a systematic and
              scientific manner and train the participants for effective
              practice thereby facilitating the evolution of technology law. The
              Moot Proposition was based on ‘Gene Editing Technology’ and its
              legal ramifications.
            </p>
          </TOP>
          <a href="/downloads">Downloads</a>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const DIV = Styled.div`
background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
`;
const COLOR = Styled.div`
padding: 20px 40px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
background: white;
@media (max-width:368px){
  padding: 10px;
}`;

const BOX = Styled.div`
width:85%;
margin:50px auto;
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    color:#E01E38;
    display:flex;
}
@media (max-width:660px){
  h1{
      font-size: 30px;
      }
}
@media (max-width:534px){
margin:30px auto;
  h1{
   font-size: 28px;
   }
 }
 @media (max-width:395px){
  padding:16px;
  h1{
   font-size: 26px;
   }
`;
const TOP = Styled.div`

 p{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    text-align:justify;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
 }
`;


