import React ,{useEffect} from "react";
import Styled from "styled-components";
import nrm from "../Images/nr_madhavamenon_centre.svg";

export default function Madhavamenoncenter() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <div>
      <DIV>
        <BOX>
      
          <h1>
            Prof. N. R. Madhava Menon Interdisciplinary Centre for Research Ethics &
            Protocols (ICREP)
          </h1>
          <COLOR>
            <TOP>
              <p>
               Prof. N. R. Madhva Menon Interdisciplinary Centre for Research Ethics and Protocols (ICREP) 
               kick started its activities in 2020 and is named in honour of the great Indian legal educationist 
               and stalwart late Padmasri Prof.(Dr.) Madhava Menon who brought about a sea change in the 
               legal education of the country. The activities of the Centre began with the launch lecture on
                10th of January 2020 by Prof. (Dr.) Michael Levitt,Noble Laurete,2013. The ICREP is envisaged
                as an independent and autonomous research centre which would undertake research and academic programs
                in the field of bioethics as well as in all areas of application of research ethics. The Centre would
                explore the possibilities of collaborative research, teaching, and exchange of scholarship with 
                institutes of international repute that specialize on Bioethics. The Centre will focus on particular
                niche areas where existence and awareness of scientific protocols is essential. The Centre tries to
                seek integrity in academic scientific pursuits by giving insights to the teaching, student and research
                community about the ethical rules and norms exiting in the realm of scientific research, thereby enhance
                the quality of scientific studies. With this end in view,  the Centre has already been vested 
                with the responsibility to conduct a course paper for all  Research Scholars registered in the University 
                as well as outside the University to undertake a two credit program on “Research and Publication Ethics”
                as envisaged by UGC. In the forthcoming academic year,the centre has plans to start a Two Year Postgraduate
                Program i.e., Masters in BioEthics (MBE) in the forthcoming academic year. It is also offering an Advanced
                Certificate Program on “Medical Law. Clinical Research and Bioethics’ which  is a 6 months program which will 
                be offered to doctors, paramedicals, those involved in medical and pharmacological studies, hospital management
                etc apart from those who have degree in law. CXurrentlt, Dr. David Hunter, Senior Lecturer, University of Adelaid,
                Australia as well as Dr.Rieder P. Lie, Professor and Head of the Department of Philosophy, University of Bergen,
                Norway will be associating with the activities of the centre. The Centre has a governing council and an advisory
                board consisting of various academicians, scientists, bioethicists, clinicians, virologists, retired judges
                 and academic experts  both within and outside India, who are assisting the centre in its activities. 
               The centre is financially supported by the Government of Kerala and is housed in the CUSAT campus. The Centre proposes to undertake research
                  and development in the field of interdisciplinary studies and would shortly introduce numerous academic initiatives of this nature.
                  <br/><span className="credits">Centre Director: Dr. Vani Kesari A. </span>


              </p>
              <a href="/downloads">Downloads</a>
            </TOP>
          </COLOR>
        </BOX>
      </DIV>
    </div>
  );
}
const DIV = Styled.div`

background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
  
`;
const COLOR = Styled.div`
background:white;
padding: 20px 40px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
@media (max-width:368px){
  padding: 10px;
}
`;
const BOX = Styled.div`
   
    width:85%;
   
    margin:50px auto;
 h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #E01E38;
    display:flex;
 }
 @media (max-width:1310px){
  h1{
    font-size: 32px;
  }
 }
 @media (max-width:660px){
  h1{
      font-size: 30px;
      }
}
@media (max-width:534px){
  margin:30px auto;
  h1{
   font-size: 28px;
   }
 }
@media (max-width:395px){
  padding: 16px;
  h1{
    font-size: 26px;
    }
}
`;

const TOP = Styled.div`
     p{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-align:justify;
        @media (max-width:640px){
          font-size: 18px;
        }
        @media (max-width:540px){
          font-size: 16px;
        }
      }
        span{
          font-family: PT Serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          font-style: italic;
          display: inline-block;
          margin-top:12px;
          @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
        }
        }
       
     
`;
