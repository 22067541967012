import React,{useEffect,useState} from 'react'
import Styled from 'styled-components'
import axios from "axios";

export default function Alumni() {

    const [president, setpresident] = useState([]);
    const [ecmembers, setecmembers] = useState([]);
    const [jointsecretery, setjointsecretery] = useState([]);
    const [secretery, setsecretery] = useState([]);
    const [tressurer, settressurer] = useState([]);
    const [visepresident, setvisepresident] = useState([]);
    // const API = process.env.REACT_APP_API;
    const API = '127.0.0.1:8000'

      useEffect(() => {
        window.scroll(0,0)
        axios
          .get(process.env.REACT_APP_API+`/alumini/ecview/`)
          .then((res) => {
            console.log(res.data.data,);
            setecmembers(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(process.env.REACT_APP_API+`/alumini/presidentview/`)
          .then((res) => {
            console.log(res.data.data,);
            setpresident(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(process.env.REACT_APP_API+`/alumini/vice-president-view/`)
          .then((res) => {
            console.log(res.data.data,);
            setvisepresident(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(process.env.REACT_APP_API+`/alumini/secretery-view/`)
          .then((res) => {
            console.log(res.data.data,);
            setsecretery(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(process.env.REACT_APP_API+`/alumini/treasurer-view/`)
          .then((res) => {
            console.log(res.data.data,);
            settressurer(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(process.env.REACT_APP_API+`/alumini/joint-secretery-view/`)
          .then((res) => {
            console.log(res.data.data,);
            setjointsecretery(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    return (
        <DIV>
        <BOX >
  
        <h1>Alumni Association </h1>
      
            <COLOR>
            
                <TOP >
        
                
                        <p>Cochin University of Science and Technology School of Legal Studies 
                            Alumni Association started functioning from the year 1997. It is 
                            registered as per the provisions of Travancore- Cochin Literary 
                            Scientific and Charitable Societies Act. It has at present about 
                            2500 members, and is managed by an Executive Committee elected by 
                            its General Body. The present Executive Committee is headed by Justice
                             (Rtd.) S. Siri Jagan, a reputed alumni of our school and who was a 
                             Judge of the Hon’ble High Court of Kerala. 

                             </p>
                             
                    <p>Alumni Association is conducting various activities including
                         two Alumni Days in a year. Special lecture are conducted on
                          specialized legal subjects for the benefit of the students
                           of the school. Lectures are delivered by the members of 
                           Alumni Association, who are experts in those specialized 
                           subjects. The Alumni Association has instituted gold medals
                            for the First Rank Holders of LLM and LLB. It includes the 
                            gold medal instituted in the memory of late Dr. A T Markose, 
                            the Founder Director of SLS. Dr. A T Markose Memorial National 
                            Moot Court Competition, 2020 was organised with the active support
                             of Alumni Association. Our law school is immensely supported by
                              Alumni in all our academic initiatives such as seminars, workshops,
                               training programmes and legal aid. The Telelegal Aid Clinic at SLS
                                was instituted with the financial support of Alumni. 

                                The present office bearers of the Executive Committee of
                                 the Association are as follows. 



                    </p>
                             
                       
                </TOP>
                
                <h3>President: </h3>
                {president.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}


                <h3>Vice Presidents:</h3> 
                {visepresident.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}


                <h3>Secretary: </h3> 
                {secretery.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}


                <h3>Treasurer: </h3>
                {tressurer.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}


                <h3>Joint Secretaries:</h3>
                {jointsecretery.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}


                <h3>EC Members:</h3>
                {ecmembers.map((res) => (
                    <h3 className="nobold">{res.name}</h3>
                ))}
                <a href="/downloads">Downloads</a>
                </COLOR>
                
            </BOX>
       </DIV>
)
}
const DIV =Styled.div`
background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
`;
const COLOR =Styled.div`
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
padding: 20px 40px;
@media (max-width:368px){
    padding: 10px;
}
`;
const BOX =Styled.div`
width:85%;
margin:50px auto;
background:white;
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 36px;
    color: #E01E38;
}
h3{
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}
h3.nobold{
    font-weight: 500;
    font-size: 20px;
}
@media (max-width:660px){
    h1{
        font-size: 30px;
        }
    h3{
        font-size: 18px; 
    }
    h3.nobold{
        font-size: 18px;
    }
}
@media (max-width:534px){
  margin:30px auto;
    h1{
     font-size: 28px;
     }
     h3{
        font-size: 16px; 
    }
    h3.nobold{
        font-size: 16px;
    }
   }
   @media (max-width:395px){
    padding:16px;
    h1{
     font-size: 26px;
     }
`;



const TOP =Styled.div`
p{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
 }
@media (max-width:660px){
    p{
        font-size: 18px;
    }  
}
@media (max-width:540px){
    display:flex;
    flex-direction:column;
    align-items:center;
    p{
        font-size: 16px;
    }
}
`;