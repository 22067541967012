import React,{useState,useEffect} from "react";
import Styled from "styled-components";
import Quotes from "../Images/quotes.svg";
import Director from "../Images/DirectorImage.svg";
import bgimage from "../Images/aboutbg.svg";
import axios from "axios";


export default function DirectorMessage() {


  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    window.scroll(0,0);
    axios
      .get(`${API}/director-messege/directorview/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);
  return (
    <div>
    <Div>
    <Content>
        <h1>Director’s Message</h1>
        <div className="main">
          <Top>
            <div className="profile">
              <img src={response.image} alt="Director-image" />
              <div className="name">
                <span className="name">{response.name}</span>
                <span className="designation">Director</span>
              </div>
            </div>
          </Top>
          <Bottom>
            <img src={Quotes} alt="Quotes" />
            <p>{response.messege}</p>
          </Bottom>
        </div>
      </Content>

    </Div>
    </div>
  );
}

const Div = Styled.div`
    background: #FAFAFA;
    width: 100%;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-position: 1px;
    background-size: cover;
`;
const Content = Styled.div`
    width:70%;
    padding: 83px 0 100px;
    margin:0 auto ;
    h1{
        font-family: Oswald;
        font-weight: normal;
        font-size: 34px;
        line-height: 36px;
        color: #E01E38;
        margin-bottom : 0 0 40px;
        
    }
    div.main{
        background:#fff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
    @media (max-width:860px){
      padding: 63px 0 80px;
      h1{
        font-size: 32px;
      }
    }
    @media (max-width:700px){
      padding: 45px 0 60px;
      h1{
        font-size: 30px;
      }
    }
      @media (max-width:700px){
        h1{
          font-size: 28px;
        }
      }
`;
const Top = Styled.div`
    margin-bottom:85px;
    padding: 70px 70px 0; 
   
    div.profile{
        display:flex;
        justify-content:center;
        text-align:center;
        gap:66px;
        div.name{
            display:flex;
            justify-content:center;
            align-items:center;
            flex-direction:column;
            span.name{
                font-family: PT Serif;
                font-style: italic;
                font-weight: bold;
                font-size: 24px;
                color: #121212;
                display:inline-block;
            }
            span.designation{
                display:inline-block;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 174.5%;
                color: rgba(18, 18, 18, 0.6);
            }
        }
    }
    @media (max-width:771px){
      padding: 50px 50px 0;
      margin-bottom:75px;
      div.profile{
        gap:40px;
        div.name{
          span.name{
            font-size: 22px;
          }
        }
      }
    }
    @media (max-width:660px){
      padding: 35px 35px;
      margin-bottom:55px;
      div.profile{
        gap:30px;
        div.name{
          span.name{
            font-size: 18px;
          }
        }
      }
    }
    @media (max-width:560px){
      margin-bottom:35px;
      div.profile{
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            width:250px;
            height:250px;
          }
         
      }
    }
`;
const Bottom = Styled.div`
    padding:0 70px 70px;
    p{
        text-indent:70px;
        text-align:justify;
        font-family: PT Serif;
        font-weight: normal;
        font-size: 20px;
        line-height: 144%;
        color: rgba(18, 18, 18, 0.87);
        margin-top:0;
    }
    @media (max-width:860px){
      padding:0 60px 60px;
      p{
        font-size: 18px;
      }
    }
    @media (max-width:700px){
      padding:0 50px 50px;
      p{
        font-size: 16px;
      }
    }
    @media (max-width:600px){
      padding:0 40px 40px;
      p{
        font-size: 16px;
      }
    }
`;
