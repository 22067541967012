import React,{ useState, useEffect } from 'react';
import Events from './Events';
import Spotlight from './Spotlight';
import Quicklinks from './QuickLinks';
import Extensions from './Extensions';
import Research from './Research';
import Photogallery from './Photogallery';
import axios from "axios";

function MainPage() {
    const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    window.scroll(0,0);
    axios
      .get(`${API}/news/viewtest/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
    return (
        <div>
            <Spotlight/>
            <Events/>
            <Research/>
            <Extensions/>
            <Photogallery/>
            <Quicklinks/>
        </div>
    )
}

export default MainPage
