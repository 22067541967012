import React,{useEffect} from 'react'
import Styled from 'styled-components'

export default function OtherCommittees() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
    return (
        <Div>
        <Content>
          <h1>Other Committees</h1>
          <div className="container">
          <Top>
                <h3>Women and Sexual Harassment Cell</h3>
                <p>It is the duty of every institution to ensure a peaceful and healthy working environment. To ensure this it becomes essential that everyone who works there feels safe and it's ensure that there is recourse available in case of any difficulties felt. With this objective in mind, along with the other legal requirements, the Women and Sexual Harassment Cell was established at the School of Legal Studies in 2015.</p>
                <p>The main objectives of the Cell are as follows. Firstly, to ensure that there is gender parity in this institution. This parity is to be ensured not just among the staff of the institution but also among the students. The second objective of the Cell is to ensure that there is adequate sensitization regarding gender parity in the institution both among its staff and students. The third objective of the Cell is to resolve any kind of disputes of minor nature, that affects the healthy working environment and gender parity, which arises at the department between the different parties involved, including both the staff and students. </p>
            </Top>
            <Top>
                <h3>Disciplinary and Grievance Committee</h3>
                <p>Law students, and future lawyers particularly, are expected to be ideal in behaviour and socially committed, which make the establishment of a department-based disciplinary committee a must. It aims to help the students of the School of Legal Studies develop a sense self-discipline and to assist them in conforming with the rules and regulations of the University. The committee not only administer sanctions commensurate with the offence(s) committed by a student, but also ensures a peaceful coexistence among and between teachers and students respectively. In collaboration with the parent teachers association the disciplinary committee conduct orientations and guidance programmes, at least yearly, with the student community in order to create a common understanding, and help them to wean themselves from any breach of discipline committed. The committee is made up of members of the faculty, parents, and selected students. The membership and decisions are to be ratified by the Department Council.
                </p>
            </Top>
            <Top>
                <h3>Anti- Ragging Committee</h3>
                <p>In order to carry out the mandates of UGC Regulations on Curbing the menace of Ragging in Higher Educational Institutions, 2009, and the Kerala Prohibition of Ragging Act, 1998, the Anti-ragging Committee is set up in the School of Legal Studies.  The main aim of the Anti-ragging Committee is to create awareness among the students about the laws and regulations that deals with ragging and the punishments thereof and to convince the students about the need to abstain from ragging. The Committee also makes arrangements for orientation programmes by professional counsellors to fresher’s and senior students. The committee also actively monitors, promotes and healthy interaction between fresher’s, junior students and senior students. The Anti-Ragging committee also makes surprise visits in the class of fresher’s to ensure that the fresher’s are not subject to ragging.  The Anti-ragging Committee consists of a group of members of faculty selected by the Department Council of the School of Legal Studies every year. 
                </p>
            </Top>
          
            <Top>
                <h3>Library Committee</h3>
                <p>School of Legal Studies constitutes a committee for library for monitoring various functions of library. Law library is a reference reserve as well as laboratory of research for students, professionals and academicians. It consists of a collection of more than thirty two thousand books.  The library also provide access to various online and offline journals. Library committee is entrusted with coordinating the purchase and maintenance of library books, journals etc. in consultation with the faculty members and students. It also will help the library to procure various materials required library. Library committee will collect suggestions from the students and other stakeholders for the better management of the library.</p>
                <p>There will be periodical stock verification and cleaning in the library and it will try to identify the lost books and journals with the help of librarian and library staff. Priorities in procuring books on legal studies for the central library will be done by the library committee in consultation with the faculty members of the School. In case of complaints in library management, the library committee may conduct a fact finding enquiry if entrusted so by the department council. Necessary actions may be suggested by the library committee for resolving such complaints to the department council. Thus library committee plays a pivotal role in managing the library which is a spearhead unit of the institution in mentoring future lawyers and academicians and training students for moot court exercises. </p>
            </Top>
           
            <a href="/downloads">Downloads</a>
          </div>
        </Content>
      </Div>
    );
}
  
const Div = Styled.div`
      background: #FAFAFA;
  
  `;
  const Content = Styled.div`
      width:85%;
      margin:50px auto;
     
      h1{
          font-family: Oswald;
          font-weight: 500;
          font-size: 32px;
          line-height: 36px;
          color: #E01E38;
      }
      div.container {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          background:#fff;
          padding:20px 40px ;
          span{
            font-family: PT Serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            font-style: italic;
          }
      }
      @media (max-width:660px){
        h1{
            font-size: 30px;
            }
    }
    @media (max-width:534px){
      margin:30px auto;
        h1{
         font-size: 28px;
         }
       }
       @media (max-width:395px){
       
        h1{
         font-size: 26px;
         }
         div.container{
          padding:16px;
         }
  `;
  const Top = Styled.div`
      h3{
        font-family: Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #0A114E;
        margin-bottom:20px;
        @media (max-width:640px){
          font-size:20px;
        }
        @media (max-width:540px){
          font-size:18px;
        }
      }
      p{
          font-family: PT Serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-align:justify;
          color: #000000;
          @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
      }
  `;
  