import React,{useEffect} from "react";
import Styled from "styled-components";
import vrk from "../Images/krishna_iyer_chair.svg";

export default function Icsi() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <div>
      <DIV>
        <BOX>
          <h1>CUSAT-ICSI ACADEMIC COLLABORATION</h1>

          <COLOR>
            <TOP>
              <p className="above">
              COCHIN UNIVERSITY OF SCIENCE AND TECHNOLOGY (CUSAT), COCHIN and THE INSTITUTE OF COMPANY SECRETARIES OF INDIA (ICSI) entered into an MOU for academic collaboration on 15/3/2021. The MOU aims to promote excellence and impart knowledge and skills in common areas of interest particularly corporate governance, corporate finance, mergers and acquisitions, securities law and regulatory compliances. The Institute of Company Secretaries of India (ICSI) is the only recognized professional body in India to develop and regulate the profession of Company Secretaries in India. The MOU signing ceremony was presided over by Dr K N Madhusoodanan, Vice-Chancellor, CUSAT. C S Nagendra D Rao, President of ICSI and Dr Meera V, Registrar, CUSAT signed the MOU on behalf of ICSI and CUSAT respectively. CS N. Balasubramanium, Chairman, Southern India Regional Council (SIRC) of ICSI, C S Devendra V Deshpande, Vice –President, ICSI, CS Baiju, Chairman, Kochi Chapter of ICSI and Dr Vani Kesari A, Director, School of Legal Studies, CUSAT offered felicitations.          </p>

              <H3>Objective:</H3> 
              <H4 className="padding">Undertake joint research projects on areas of mutual interest.</H4>
              <H4 className="padding">Facilitate the conduct of Specialised Training Programmes in School of Legal Studies, CUSAT for the Faculty Members, Research Scholars and Students of School of Legal Studies, CUSAT on areas of mutual interest by providing experts on its rolls.</H4>
              <H4 className="padding">Jointly organise Workshops, Seminars, Continuing Education and Training programmes and similar Academic Programmes for Practicing Professionals, Corporate Executives & Faculty Members, Research Scholars and students of School of Legal Studies, CUSAT on themes of Topical and Professional interest on equal surplus/deficit sharing basis.</H4>
              <H4 className="padding">Reciprocate participation in National and International conferences organised by the parties hereto.</H4>
              <H4 className="padding">Extend help and Co-operation in developing Curriculum of Academic and Continuing Education Programmes, on such terms as may be mutually agreed to including Joint Meeting of Curriculum Development Committees/bodies.</H4>

              <H3>Coordinator:</H3> 
              <H4>Dr Binumole K, Associate Professor, SLS</H4>
              <H4>Dr Preetha S, Assistant Professor, SLS</H4>
            </TOP>
            <a href="/downloads">Downloads</a>
          </COLOR>
        </BOX>
      </DIV>
    </div>
  );
}
const H3 = Styled.h3`
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
    @media (max-width:395px){
      font-size: 14px;
    }
   `;
   const H4 = Styled.h4`
   font-family: PT Serif;
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
   @media (max-width:640px){
    font-size: 18px;
  }
  @media (max-width:540px){
    font-size: 16px;
  }
  @media (max-width:395px){
    font-size: 14px;
  }`
const DIV = Styled.div`
    background: #FAFAFA;
    display:flex ;
    justify-content:center;
    align-items: center;
    width:100%;`;

const BOX = Styled.div`
   
    width:85%;
    margin:50px auto;
    
    h1{
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        font-weight:500;
        color: #E01E38;
        display:flex;
    }
    @media (max-width:660px){
        h1{
            font-size: 30px;
            }
    }
    @media (max-width:534px){
      margin:30px auto;
        h1{
         font-size: 28px;
         }
       }
       @media (max-width:395px){
        padding:16px;
        h1{
         font-size: 26px;
         }
`;
const COLOR = Styled.div`

    background: white;
    padding: 20px  40px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    @media (max-width:368px){
        padding: 10px;
    }
`;

const TOP = Styled.div`

    .padding{
        padding-left:30px;
    }
     p{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        text-align:justify;
        @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
        }
   
`;
