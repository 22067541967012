import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Styled from "styled-components";
import axios from "axios";
import './Style.css';                                 

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
    //   fontSize: theme.typography.pxToRem(15),
    //   fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export default function LibraryStaff() {

  const classes = useStyles();
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    window.scroll(0,0);
    axios
      .get(`${API}/faculty/libraryview/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
    <DIV>
      <h1>LIBRARY STAFF</h1>
      <div className="cover">
        {response.map((res) => (
          <Accordion id="Faculty">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                <TOP>
                  <div className="left">
                    <img src={res.image} />
                  </div>
                  <div className="right">
                    <table>
                      
                      <tbody>
                        <tr className="name">
                          <td>Name</td>
                          <td>:</td>
                          <td>{res.honorific_name}. {res.name}</td>
                        </tr>
                        <tr>
                          <td>Designation</td>
                          <td>:</td>
                          <td>{res.designation}</td>
                        </tr>
                        <tr>
                          <td>Contact</td>
                          <td>:</td>
                          <td>{res.phone}</td>
                        </tr>
                        <tr>
                          <td>E-Mail</td>
                          <td>:</td>
                          <td>{res.email}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>{res.cusat_mail}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </TOP>
              </Typography>
            </AccordionSummary>
          </Accordion>
        ))}
      </div>
    </DIV>
    </div>
  );
}

const DIV = Styled.div`
  @media screen and (max-width:550px){
    width:95%;
  }
  width:85%;
  max-width:847px;
  margin :50px auto;
  h1{
    margin:38px 0;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: #E01E38;
  }
  div.cover{
    margin-top:30px;
  }
  div.person{
    border: 0.5px solid #000000;
    box-sizing: border-box;
  }
`;
const TOP = Styled.div`
  display:flex;
  gap:39px;
  @media screen and (max-width:721px){
    gap:0;
  }
  @media screen and (max-width:551px){
    gap:5px;
  }
  div.left{
    img{
      display:block;
      width: 170px;
      height: 200px;
      @media screen and (max-width:571px){
        width: 126px;
        height: 170px;
      }
      @media screen and (max-width:571px){
        width: 100px;
        height: 140px;
      }
     
    }
  }
  div.right{
    table{
      border-collapse: separate;
      border-spacing:10px 10px;
      @media screen and (max-width:675px){
        border-spacing:05px 05px;
      }
    }
    tr{
      font-family: "Oswald";
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 21px;
      color: #000000;
      @media screen and (max-width:780px){
        font-size:16px;
      }
      @media screen and (max-width:675px){
        font-size:14px;
      }
      @media screen and (max-width:551px){
        gap:5px;
        line-height: 15px;
      }
    }
    tr.name {
      
      font-style: normal;
      font-weight: normal;
      
    }

  }
`;
const Bottom = Styled.div`
  display:block;
  div{
    p{
      font-family: "PT Serif";
      width:85%;
      margin :10px auto;
      text-align: justify;
      
      @media screen and (max-width:780px){
        font-size:16px;
      }
      @media screen and (max-width:675px){
        font-size:14px;
        width:90%;
      }
    }
    div{
      // margin-left: 489px;
      display:flex;
      justify-content:end;
      align-items:center;
      gap:19px;
      span{
        display:block;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #BB0000;
      }
      button{
        padding: 0;
        border: none;
        background: none;
        display:flex;
        align-items:end;
        img{
          display:block;
          padding: 11px;
          border-radius:50%;
          background:#BB0000;
        }
        img.key{
          padding: 15px 13px; 
        }
        img.linkedin{
          padding:4px;
        }
      }
    }
  }
`;

