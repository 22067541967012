import axios from "axios";
import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import FramePic from "../Images/welcomeImg.svg";
import DirPic from "../Images/DirectorImage.svg";
import { Link } from "react-router-dom";
import "../components/Style.css";
import moment from "moment";
import Director from "../Images/DirectorImage.svg";



export default function Events() {
  const [response, setresponse] = useState([]);
  const [messege, setmessege] = useState([]);
  const API = process.env.REACT_APP_API; 
  console.log(response.length)
  useEffect(() => {
    axios
      .get(`${API}/news/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(`${API}/director-messege/directorview/`)
      .then((res) => {
        console.log(res.data.data);
        setmessege(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("responsee", response);
  const viewMore = () => {
    window.location.replace("/news");
  };
  const readMore = () => {
    window.location.replace("/directorMsg");
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
    responsive: [
      ,
      {
        breakpoint: 960,
        settings: {
          dots: false,
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Div>
      <Top>
        <DivImg>
          <h6 className="welcome">Welcome to</h6>
          <h3 className="welcome">School of Legal Studies</h3>
          <img src={FramePic} alt="Image" />

          <p>
           School of Legal School is one of the oldest Law Schools
            in India. The Law School was initially started as a part of the
            Kerala University in the year 1962 and subsequently became the part of Cochin
            University in the year 1971.
            
            SLS has made valuable contributions to the study and research of law
            in the past 54 years, fully living up to the motto of CUSAT{" "}
            <i style={{textAlign:"center"}}>‘Tejaswinavadhitamastu’</i> meaning{" "}
            <i>
              ‘may the wisdom accrued deify us both-the teacher and the taught
              and percolate to the universe in its totality...’
            </i>
          </p>

          <Link to="/aboutUs">
            <div className="read">READ MORE</div>
          </Link>
        </DivImg>
      </Top>
      {response.length >3 ? (
      <Middle>
        <h1>News & Events</h1>

        <div>
          <Slider {...settings}>
            {response?.slice(0, 3).map((res) => (
              <DIV>
                <span>{moment(res.date).format("DD/MM/YYYY")}</span>
                <h3>{res.title}</h3>
                <p>
                  {res.discription?.slice(0, 200)}
                  {res.discription?.length > 200 && "..."}
                </p>
                {res.discription?.length > 200 && (
                 < a style={{textDecoration:"none"}} href="/news" ><span style={{ fontSize: "12px" }}>Read More...</span></a>
                )}
              </DIV>
            ))}
          </Slider>
        </div>
        <ButtonBox onClick={() => viewMore()}>
          <a href="/news">VIEW ALL</a>
        </ButtonBox>
      </Middle>
      ):(
        <Middle>
        <h1>News & Events</h1>

        <div>
          <ul>
            {response.map((res) => (
              <DIVLI>
                <span>{moment(res.date).format("DD/MM/YYYY")}</span>
                <h3>{res.title}</h3>
                <p>{res.discription}</p>
              </DIVLI>
            ))}
          </ul>
        </div>
        <ButtonBox onClick={() => viewMore()}>
          <a href="/news">VIEW ALL</a>
        </ButtonBox>
      </Middle>

      )}



      
<Bottom>
        <h2>Director’s Message</h2>
        <label>{messege.name} </label>

        <div className="message">
          <img src={messege.image} alt="directorImage" />
        </div>
        <Quote>
          <p>{messege.messege_home}</p>
          <ButtonB>
            <a href="/directorMsg">READ MORE</a>
          </ButtonB>
        </Quote>
      </Bottom>


    </Div>
  );
}
const Div = Styled.div`
    display:flex;
    background:#fff;
    margin:20px;
    @media(max-width:960px){
      display:flex;
      flex-direction:column;
      margin:0px;
    }`;

const Top = Styled.div`
    width:34%;
    background: linear-gradient(0deg, #0A114E, #0A114E), #FFFFFF;
    @media(max-width:960px){
      width:100%;
     
    }`;
const DivImg = Styled.div`
    h6.welcome{
      font-family:Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      color: #FFFFFF;
      margin:0px;
      margin-left:10%;
      margin-top:16px;
    }
    h3.welcome{
      font-family:Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 37px;
      text-align: left;
      color: #FFFFFF;
      margin:0px;
      margin-left:10%;
      margin-bottom:10px;
    }   
    img{
      width:100%;
      
    }
    p{
      margin: 32px 55px;
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 144%;
    text-align:justify;
    letter-spacing:1px;
    color: #fff;
    width:fit-content;
    }
    a{
      display:flex;
      flex-direction:row;
      justify-content:center;
      text-decoration:none;
      div.read{
        text-decoration:none;
        font-family: PT Serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #fff;
      cursor:pointer;
      margin-top: -1px;
      margin-bottom:30px;
      }
    }
    @media(max-width:1300px){
      p{
       
      }
    }
    @media(max-width:1200px){
      p{
       
      }
    }
    @media(max-width:960px){
      display:flex;
      flex-direction:column;
      align-items:center;
      img{
        width:70%;
      }
      h3.welcome{
        margin-left:0px;
        font-size:30px;
      }
      h6.welcome{
        margin-left:0px;
        font-size:26px;
      }
      p{
        font-size: 17px;
      }
    }
    @media(max-width:860px){
      p{
        font-size: 17px;
      }
    }
    @media(max-width:768px){
    h3.welcome{
      font-size: 26px;
    }
    h6.welcome{
      font-size: 22px;
    }
    p{
      font-size: 16px;
    }
    
    @media(max-width:540px){
      
    }
    h3.welcome{
      font-size: 24px;
    }
    h6.welcome{
      font-size: 22px;
    }
    p{
      font-size: 15px;
    }
    }
    @media(max-width:411px){
      h3.welcome{
        font-size: 24px;
      }
      h6.welcome{
        font-size: 20px;
      }
      p{
        font-size: 14px;
      }
      a{
        div.read{
          font-size: 11px;
        }
      }
    }
    `;

const Middle = Styled.div`
    width:31%;
    margin-left:40px;
    margin-right:22px;
    background: #fff;
    div{
      height:unset !important;
    }
    max-height:849px;
    /* overflow:scroll; */
    h1{
      font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        color:  #2929a3;
        padding-left:16px;
        }
    h3{
           
      font-family: PT Serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #BB0000;
    }
    span{
      font-family: PT Serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
    }p{
      font-family: PT Serif;
    }
    @media(max-width:960px){
      width:80%;
      h1{
        font-size:28px;
      }
     
    }
    @media(max-width:768px){
      width:80%;
     h1{
      font-size: 26px;
     }
     h3{
      font-size: 17px;
     }
     span{
      font-size: 13px;
     }
    }
    @media(max-width:600px){
      h1{
        font-size: 24px;
       }
       h3{
        font-size: 16px;
       }
       span{
        font-size: 12px;
       }
    }
    @media(max-width:411px){
      h1{
        font-size: 24px;
       }
       h3{
        font-size: 14px;
       }
       span{
        font-size: 10px;
       }
      }
    }`;

const ButtonBox = Styled.div`
    display:flex;
    flex-direction:row;
    justify-content: left;
    border:none;
    margin-top: 25px;
    a{
      text-decoration:none;
      font-family: PT Serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #E01E38;
      cursor:pointer;
      margin-bottom:20px;
    }
    @media(max-width:411px){
      a{
        font-size: 11px;
      }
    }
    `;
const Bottom = Styled.div`
    width:34%;
    background: linear-gradient(0deg, #0A114E, #0A114E), #FFFFFF;
    h2{
     text-align:left;
     margin-left:15%;
     margin-bottom:26px;
     margin-top:28px;
     font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    color: #fff;
 }
 label{
  font-family: PT Serif;
  display:block;
  font-size: 24px;
  color: #fff;
  font-weight:500;
  padding-bottom:14px;
  text-align:left;
  margin-left:15%;
 }
 img{
  width: 70%;
  
 }
div.message{
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
}

 @media(max-width:960px){
  width:100%;
 img{
   width:30%;
 }
 h2{
  text-align:center;
  margin-left:0px;
 }
 label{
   text-align:center;
   margin-left:0px;
 }
};
@media(max-width:768px){
  width:100%;
  h2{
    font-size:25px;
  }
 img{
   width:30%;
 }
 div.message{
  div.designation{
    label{
      font-size:18px;
    }
  }
}
};
@media(max-width:540px){
  width:100%;
 img{
   width:35%;
 }
 label{
  font-size:18px;
 }
};
@media(max-width:411px){
  width:100%;
    h2{
    font-size:23px;
      }
    img{
      width:45%;
    }
    label{
      font-size:16px;
        }
}`;
const ButtonB = Styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    a{
      text-decoration:none;
      font-family: PT Serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #fff;
      cursor:pointer;
      margin-top: -1px;
      margin-bottom:30px;
    }
    @media(max-width:411px){
      a{
        font-size: 11px;
      }
    }`;
const Quote = Styled.div`
p{
  margin: 32px 55px;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 144%;
  text-align:justify;
  letter-spacing:1px;
  color: #fff;
  width:fit-content;
}
@media (max-width:860px){
  p{
    font-size: 18px;
  }
}
@media (max-width:768px){
  p{
    font-size: 18px;
  }
}
@media (max-width:540px){
  p{
    font-size: 16px;
  }
}
@media (max-width:411px){
  p{
    font-size: 14px;
  }
}`;

const DIV = Styled.div`
color: linear-gradient(0deg, #0A114E, #0A114E), #FFFFFF;
padding-left:16px;
margin-bottom:30px;
h3{
  display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
p{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
`;
const DIVLI = Styled.li`
color: linear-gradient(0deg, #0A114E, #0A114E), #FFFFFF;
padding-left:16px;
margin-bottom:30px;
list-style:none;
h3{
  display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
p{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
`;

const Box = Styled.div``;
