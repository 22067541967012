import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import Pdf from "../Images/pdf.png";
import axios from "axios";

function PHDProgram() {
  const clickHere = () => {
    window.location.replace("/research");
  };
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;
  useEffect(() => {
    window.scroll(0, 0);
    axios
      .get(`${API}/course/PhD/view/`)
      .then((res) => {
        console.log("data", res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Container>
        <Box>
          <h1>Ph.D. Programme</h1>
          <Bottom>
            <Top>
              The School of Legal Studies being a research centre aims at
              developing the research in Law and to find solutions to social and
              legal problems. Research scholars are assessed twice in every year
              to gauge the progress. Both doctrinal and non- doctrinal research
              take place under the guide-ship of eminent teachers. Research is
              being done in all thrust areas of Laws such as Criminal Law,
              Constitutional Law, Environmental Law, Women’s Rights, Labour Law,
              Taxation Law, Maritime Law, Commercial Law, Human Rights Law, and
              Children’s Rights etc.
            </Top>
            <Div>
              <p className="number">Number of Seats</p>
              <p className="colon">:</p>
              <p className="medium">Depends on the vacancy in each year</p>
            </Div>
            <Div>
              <p className="number">Admission</p>
              <p className="colon">:</p>
              <p className="medium">
                The Application forms for Ph. D Programme can be obtained from
                the SLS Office and to be submitted to the SLS Office within the
                time prescribed in the CAT notification. Admission will be done
                through Departmental Admission Test (DAT) and an Interview based
                on research proposal. Interested candidates may contact the SLS
                Office directly for further details.
              </p>
            </Div>
            <DIV>
              <div className="first">List of Current Research Scholars</div>
              <Link to="/research">CLICK HERE</Link>
            </DIV>
            <DIV>
              <p>Ph D - Program details -web links</p>
              <a
                target="_blank"
                style={{ color: "blue" }}
                href="https://cusat.ac.in/student/phd-regulations"
              >
                https://cusat.ac.in/student/phd-regulations{" "}
              </a>
            </DIV>
            <p className="end">
              ** Subject to changes made to these regulations from time to time
              by the University.
            </p>

            {response?.map((res) => (
              <>
                <a href={res.file} target="_blank">
                  <img src={Pdf} alt="pdf" />
                  <h3>{res.file_name}</h3>Read more
                </a>

                <Link to="/phdarchive">
                  <p className="end">Click here to view archived files</p>
                </Link>
              </>
            ))}
          </Bottom>
        </Box>
      </Container>
    </div>
  );
}

export default PHDProgram;

const DIV = Styled.div`
display:flex;
align-items:center;
div.first{
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000;

}
a{
    padding-top:3px;
    margin-left:20px;
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size:17px;
    line-height: 17px;
    color: #BA090B;
    text-decoration:none;
 }
 @media (max-width:860px){
    div.first{
        font-size: 18px;
    }
    a{
        font-size:16px;
    }
 }
 @media (max-width:860px){
    div.first{
        font-size: 16px;
    }
    a{
        font-size:15px;
    }
 }
 @media (max-width:540px){
    div.first{
        font-size: 14px;
    }
    a{
        font-size:12px;
    }
 }
 @media (max-width:430px){
    div.first{
        font-size: 12px;
    }
    a{
        font-size:10px;
    }
 }
`;
const Div = Styled.div`
display:flex;

   p.number{
       
        font-family: PT Serif;
        font-style: normal;
        font-weight: bold;
        font-size:20px;
        line-height: 24px;
        color: #000000;
        width:20%;
        text-align:justify;
   }
   p.colon{
    width:8%;
  }
  p.medium{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size:20px;
    line-height: 24px;
    color: #000000;
    width:70%;
    text-align:justify;
  }
  @media (max-width:980px){
    p.number{
        
        width:25%;
    }
    p.medium{
       
        width:65%;
    }
    p.colon{
        width:10%;
    }
  }
  @media (max-width:860px){
    p.number{
        font-size:18px;
        width:25%;
    }
    p.medium{
        font-size:18px;
        width:65%;
    }
    p.colon{
        width:10%;
    }
  }
  @media (max-width:710px){
    p.number{
      
        width:30%;
    }
    p.medium{
       
        width:60%;
    }
    p.colon{
        width:10%;
    }
  }
  @media (max-width:600px){
    p.number{
      
        width:33%;
    }
    p.medium{
       
        width:55%;
    }
    p.colon{
        width:8%;
    }  
  }
  @media (max-width:540px){
    p.number{
        font-size:16px;
        width:35%;
    }
    p.medium{
        font-size:16px;
        width:50%;
    }
    p.colon{
        width:10%;
    }
  }
  @media (max-width:480px){
    p.number{
        font-size:16px;
        width:40%;
    }
    p.medium{
        font-size:16px;
        width:40%;
    }
    p.colon{
        width:10%;
    } 
  }
  `;

const Container = Styled.div`
display:flex;
background: #FAFAFA;
align-items:center;`;

const Box = Styled.div`
width:85%;
margin:0 auto;
margin-top:50px;
margin-bottom:60px;
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    color: #E01E38;
    margin-bottom:24px;
   
}
p.end{
    font-family: PT Serif;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
}
@media (max-width:860px){
    margin-top:44px;
    margin-bottom:35px;
h1{
    font-size: 28px;
    font-weight:500;
} p.end{
    font-size: 18px;
}
}
@media (max-width:600px){
    margin-top:28px;
    margin-bottom:25px;
h1{
    font-size: 26px;
    font-weight:500;
} p.end{
    font-size: 16px;
}
}`;

const Bottom = Styled.div`
background: #fff;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
padding: 10px 40px;
img{
  width:70px;
  height:75px;
}

@media (max-width:860px){
    padding:20px 30px 40px 30px;   
} 
}
@media (max-width:600px){
    p.Top{
        font-size: 16px;
    }
}`;
const Top = Styled.p`
font-size:20px;
font-family: PT Serif;
line-height:24px;
text-align:justify;
@media (max-width:860px){
    font-size:18px; 
}
@media (max-width:600px){
    font-size:16px;
}
`;
