import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Style.css";
import Pdf from "../Images/pdf.png";

export default function Downloads() {
  const API = process.env.REACT_APP_API;
  const [response, setresponse] = useState([]);

  useEffect(() => {
    axios
      .get(`${API}/course/admin-file-upload/view/`)
      .then((res) => {
        setresponse(res.data.data);

        console.log(res.data.data, "jjk");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div id="downloads">
      <h1 style={{ textAlign: "center" }}>Downloads</h1>
      {response.map((res)=>(

      <div className="download-section">
        <h2>{res.file_name}</h2>
        <a href={res.file} target="_blank" className="down">
          <img style={{ width: "55px", height: "55px" }} src={Pdf} alt="pdf" />
          Read more
        </a>
      </div>
      ))}
    </div>
  );
}
