import React,{ useState, useEffect }  from "react";
import Styled from "styled-components";
import Pdf from "../Images/pdf.png";
import axios from "axios";
import { Link } from "react-router-dom";

function ThreeYearLLB() {
  const [response, setresponse] = useState([]);
    const API = process.env.REACT_APP_API;
  
    useEffect(() => {
      window.scroll(0,0);
      axios
        .get(`${API}/course/three_year_llb/view/`)
        .then((res) => {
          console.log(res.data.data);
          setresponse(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  return (
    <div>
      <Container>
        <Box>
          <h1>Three Year LLB</h1>
          <Bottom>
            <div className="innerbox">
              <p className="top">
                This course is run in the evening and thus as an academic course
                having no prospects to enrol as an Advocate. This course is
                aimed at helping the employees to pursue their studies along
                with employment and to have an understanding of Law. This course
                is also aimed at helping them to advance in their career. This
                course is handled not only by the academicians but also by
                eminent Lawyers. This gives the students a very good practical
                understanding about various subjects especially Drafting
                Pleading and Conveyancing, Moot Court, Code of Civil Procedure,
                Code of Criminal Procedure, Law of Evidence etc.{" "}
              </p>
              <Div>
                <p className="number">Numbers of Seats</p>
                <p className="colon">:</p>
                <p className="medium">40 + Supernumerary</p>
              </Div>
              <Div>
                <p className="number">Duration</p>
                <p className="colon">:</p>
                <p className="medium">3 years (Six Semesters)</p>
              </Div>
              <Div>
                <p className="number">Instruction Medium</p>
                <p className="colon">:</p>
                <p className="medium">English</p>
              </Div>
              <Div>
                <p className="number">Curriculum</p>
                <p className="colon">:</p>
                <p className="medium">
                  The subjects for the course shall be prescribed by the Cochin
                  University of Science and Technology from time to time in
                  accordance with the rules of the Bar Council of India.
                </p>
              </Div>
              <H6>Minimum Qualifications Required for Applying:</H6>
              <p className="end">
              The candidate shall have a minimum of 50% of marks in aggregate of the qualifying degree examination. 
              </p>
              <p className="end">
              Candidates belonging to Scheduled Castes and Scheduled Tribes are eligible for 5% relaxation in qualifying exams. 
              </p>
              <p className='end'>
              5% relaxation in qualifying marks is given to SCBC candidates applying for Three year LL.B.
              </p>
              <Div>
                <p className="number">Admission Procedure</p>
                <p className="colon">:</p>
                <p className="medium">
                  Admission through Common Entrance Test (CUSAT - CAT) **
                </p>
              </Div>
              <p className="disclaimer">
                ** Subject to changes made to these regulations from time to
                time by the University.
              </p>
              {response?.map((res) => (
              <>
                <a href={res.file} target="_blank">
                  <img src={Pdf} alt="pdf" />
                  <h3>{res.file_name}</h3>Read more
                </a>

                <Link to="/phdarchive">
                  <p className="end">Click here to view archived files</p>
                </Link>
              </>
            ))}
            
            </div>
          </Bottom>
        </Box>
      </Container>
    </div>
  );
}

export default ThreeYearLLB;

const Bottom = Styled.div`
background:#fff;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
img{
  width:70px;
  height:75px;
}
div.innerbox{
padding:20px 30px;
@media (max-width:860px){
    padding:20px 30px;   
}  
}
p.top{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
}
@media (max-width:860px){
    p.top{
        font-size: 18px;
    } 
}
@media (max-width:600px){
    p.top{
      font-size: 16px;
    }
}`;

const H6 = Styled.h6`
    font-family: PT Serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin:0px;
    @media (max-width:860px){
        font-size: 18px; 
    }
    @media (max-width:600px){
     
        font-size: 16px;
    }`;
const Div = Styled.div`
display:flex;

   p.number{
       
        font-family: PT Serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        width: 20%;
   }
   p.colon{
    width: 8%;
  }
  p.medium{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 70%;
    text-align:justify;
  }
  @media (max-width:860px){
    p.number{
        font-size:18px;
    }
    p.medium{
        font-size:18px;
    }
}
@media (max-width:640px){
  p.number{
      font-size:16px;
  }
  p.medium{
      font-size:18px;
  }  
}
@media (max-width:600px){
  p.number{
      font-size:15px;
  }
  p.medium{
      font-size:16px;
  }  
}
@media (max-width:540px){
  p.number{
      width:35%;
  }
  p.medium{
     width:50%;
  }  
  p.colon{
      width:10%;
  }
}
  
  `;

const Container = Styled.div`
display:flex;
background: #FAFAFA;
align-items:center;`;

const Box = Styled.div`
width:85%;
margin:50px auto;
p.end{
    font-family: PT Serif;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
text-align:justify;
color: #000000;
}
p.disclaimer{
    font-family: PT Serif;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom:51px;
    text-align:justify;
  }
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height:36px;
    color: #E01E38;
    font-weight:500;
    
}
@media (max-width:860px){
    
    h1{
        font-size: 28px;
        font-weight:500;
    }
    p.end{
        font-size: 18px;
    }
    p.disclaimer{
        font-size: 18px;
    }
}
@media (max-width:640px){
    h1{
        font-size: 26px;
        font-weight:500;
    }
}
@media (max-width:600px){
    margin-top:30px;
    margin-bottom:30px;
    h1{
        font-size: 26px;
        font-weight:500;
    }
    p.end{
        font-size: 16px;
    }
    p.disclaimer{
        font-size: 16px;
    }
}`;
