import React,{useEffect} from 'react'
import Styled from 'styled-components'




export default function Culr() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    
    <DIV>
     
      <BOX>

          <h1>Cochin University Law Review</h1>
    
        <COLOR>
          <TOP>
              <p>
              The Cochin University Law Review (CULR) (ISSN No. 0970-0331) is a
              flagship publication of the School of Legal Studies which was
              incepted from 1974 onwards. This is a peer reviewed journal with
              editorial committee consisting of eminent judges, lawyers and
              academicians. CULR gives a platform for scholars to express their
              views on contemporary legal issues in the form of articles, case
              comments, short notes and book review. There were many occasions
              in which CULR released special issues for dealing with significant
              topics of importance like Tribal Rights, Human Rights. etc. The
              lectures delivered in The Public Law Lectures which consist of
              three sessions dealing with various aspects of public law like
              Constitutional Law, Human Rights Law, Administrative Law,
              Environmental Law etc. are also published in CULR. Where eminent
              Judges, Lawyers, and academicians have vividly analyzed the
              various aspects of legal issues.  
            </p>

           
          </TOP>
          <a href="/downloads">Downloads</a>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const DIV =Styled.div`
background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
`;
const COLOR =Styled.div`
padding:20px 40px;
background: white;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
@media (max-width:368px){
  padding: 10px;
}
`;

const BOX =Styled.div`

    width:85%;
    margin:50px auto;
    h1{
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 36px;
      color: #E01E38;
      display:flex;
    }
    @media (max-width:660px){
      h1{
          font-size: 30px;
          }
  }
  @media (max-width:534px){
    margin:30px auto;
      h1{
       font-size: 28px;
       }
     }
     @media (max-width:395px){
      padding:16px;
      h1{
       font-size: 26px;
       }
 
`;

const TOP = Styled.div`

     p{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
        text-align:justify;
        @media (max-width:640px){
          font-size: 18px;
        }
        @media (max-width:540px){
          font-size: 16px;
        }
      }
     

`;

