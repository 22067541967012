
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Styled from "styled-components";
import "./Style.css";
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
   
  },
});

export default function ListOfResearchScholars() {
  const classes = useStyles();
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    axios
      .get(`${API}/research_scholors/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <DIV>
      <h1>List of Research Scholars </h1>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Sl No.</TableCell>
              <TableCell>Reg No.</TableCell>
              <TableCell align="left">Name </TableCell>
              {/* <TableCell align="left">Date of registration</TableCell> */}
              <TableCell align="left">Topic of research</TableCell>
              <TableCell align="left">Net/JRF</TableCell>
              <TableCell align="left">Supervising guide</TableCell>
        
            </TableRow>
          </TableHead>
          <TableBody>
          {response.map((res,index) => (
              <TableRow >
                <TableCell component="th" scope="row">{index+1}</TableCell>
                <TableCell align="left">{res.reg_no}</TableCell>
                <TableCell align="left">{res.name}</TableCell>
                {/* <TableCell align="left">{moment(res.date).format("DD/MM/YYYY")}</TableCell> */}
                <TableCell align="left">{res.research_area}</TableCell>
                <TableCell align="left">{res.net_jrf}</TableCell>
                <TableCell align="left">{res.supervising_guide}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DIV>
  );
}

const DIV = Styled.div`
padding:50px;
margin-bottom:50px;
h1{
    font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #E01E38;
}
@media (max-width:768px){
  padding:35px;
  h1{
    font-size: 28px;
  }
}
@media (max-width:411px){
  padding:24px;
  h1{
    font-size: 26px;
  }
}`;
