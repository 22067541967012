import React,{useEffect} from 'react'
import Styled from 'styled-components'


export default function ActivityClub() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  
    return (
        <Div>
        <Content>
          <h1>Activity Clubs</h1>
          <div className="container">
            <Top>
                <h3>Entrepreneurship Development Club</h3>
                <p>The aim of the entrepreneurship development club is to inculcate the culture of entrepreneurship among the students and to 
                  equip them with the skills, techniques and confidence required for an entrepreneur. One of the main objective of the club is
                   to enhance the students with entrepreneurial spirit, create awareness about technology incubation and to create value added
                   jobs and services.  The entrepreneurship Development club of our School was inaugurated on September 08, 2015 by 
                   Mr. K Unneenkutty, CEO & Executive Director, Kerala Institute of Entrepreneurship Development (KIED). The Club has
                    initiated various skill development programmes, industrial visits, workshops, seminars/webinars, and interactions with 
                    entrepreneurs to enrich and promote entrepreneurial traits among our students which will enable them to be blooming 
                    entrepreneurs of the future to build the nation towards economic prosperity.
                </p>
                <span>Coordinator: Dr. Nemat Sheereen S.</span>
            </Top>
            <Top>
                <h3>Management Club</h3>
                <p>Management Club at School of Legal Studies, Cochin University of Science and Technology stands for innovation, creativity, and
                   expressiveness. Mentored by the management faculty of the institute, the club enables students to stay in touch with the changing
                    trends and tactics in management and provides a platform to interact with industry experts. It also aims to promote communication
                     between the students of prominent B-schools through national-level management events and quizzes as well as intra-college events. The club is a keen promoter of creativity and innovation and tries to inculcate the same in the students through regular events.</p>
                <span>Coordinator: Dr. Sreejith S.</span>
            </Top>
            <Top>
                <h3>Maritime Club</h3>
                <p>The Maritime club, School of Legal Studies, works for the conscious and continuous development of Knowledge related to maritime
                   field and offer a platform where students sharing similar interests can come together one roof to exchange knowledge and ideas on 
                   the maritime trade, Law of the seas and the regulatory trends. Ever since its inception, the club is trying its best to keep the 
                   students in touch with the regulatory developments in the maritime filed by organizing activities lectures and talks The club also 
                   plans to open the door to a world of knowledge by convening various quiz programs and talks by eminent personalities. 

                </p>
                <span>Coordinator: Dr. Binumole K.</span>
            </Top>
            <Top>
                <h3>Nature Club</h3>
                <p>The Nature Club was inaugurated in 2014 by Prof. (Dr.) P. Leelakrishnan, former Director, School of Legal Studies and expert in 
                  environmental law. The club is constituted to create awareness among students about nature conservation. The club aims to sensitise
                   students about the environment through activities such as field works, planting of trees, special lectures and seminars. The club 
                   is committed to promote environment friendly lifestyle amongst students. The membership of the club is open to students who are 
                   committed and interested in nature conservation. A group of faculty members will be selected by the School of Legal Studies every
                    year to coordinate the activities of the Debate Club.</p>
            </Top>
            <Top>
                <h3>United National Academic Impact Chapter SLS</h3>
                <p>United Nations Academic Initiative, UNAI is the key for ensuring the education, its opportunity and access across the world.
                   UNAI aims to achieve the principles enshrined in the UN Charter and to address poverty, building capacity, conflict resolution, 
                   protection of human rights etc. School of Legal Studies is a member of UNAI since 2017. In order to fulfil its objective SLS
                    Chapter has organised several events in the past years and is continuing with the same. </p>
                    
            <span className="credits">Coordinators: Dr. Aneesh V. Pillai & Smt. Arathi Ashok</span>
            </Top>
            <Top>
                <h3>Guild for Legal Research Orientation and Writing (GLOW)</h3>
                <p>Established in the year 2015, GLOW is one of the clubs established with the view of enhancing the co-curricular activities of 
                  students of the department. To be a good lawyer or law teacher it is essential that students of law excel in legal research and
                   be well articulate in legal writing. It is the obligation of every law school to ensure that this is achieved. It is with this 
                   larger objective in mind this club has been created. </p>
                <p>The objectives of the club are threefold. Firstly, to expose students to complex and latest issues of law. The law is changing
                   rapidly and the impact Technology has or it is also changing very fast. GLOW acts as a platform to introduce students to these
                    areas of law, in general, and its overlap with technology, in particular. Secondly, to inculcate in the students a research 
                    interest in the latest areas of law. Thirdly, GLOW shall be a platform for students to express themselves particularly in writing 
                    on the various areas of law. This Committee consists of a group of members of faculty selected by the Department Council of the 
                    School of Legal Studies every year.</p>
            </Top>
    
            <Top>
                <h3>Arts and Literary Club</h3>
                <p>Arts and Literary Club aims to elicit the creative arts and writing talents of students of the School of Legal Studies, CUSAT.
                   It gives encouragement and platforms to showcase their art and literary works. The club organises workshops, exhibitions of 
                   artworks, literary speeches, film festivals, etc. to lift the skills of students. It targets to develop and exhibit skills in 
                   logical and rational analysis through effective artworks and works of literature. It also wishes to raise and highlight the 
                   hidden talents of the students in limelight. This Committee consists of a group of members of faculty selected by the 
                   Department Council of the School of Legal Studies every year. 

</p>
            </Top>
            <Top>
                <h3>Debate Club
</h3>
                <p>
                The Debate Club of this school aim is to empower the law students with the skills of expressing their beliefs clearly and concisely. It enhances the public speaking skill of the students and prepares them for effective communication in a formal setting. Different activities are carried out to help students with their critical thinking and confident communication and also helps them learn how to disagree and reason with people without disputing. Apart from that, the club ensures that the students keep updated of the current events in various disciplines around the world. The students are encouraged to 
                participate in debate competitions, conferences and moot courts hosted by various national and international institutions to enhance their skills. The club creates a platform for the students of this school to learn to interact friendly with students from diverse regions and disciplines and also exchange ideas on a wide-range of topical and social issues. A group of faculty members will be selected by the School of Legal Studies every year to coordinate the activities of the Debate Club. 
</p>
            </Top>
            <a href="/downloads">Downloads</a>
          </div>
        </Content>
      </Div>
    );
}
  
const Div = Styled.div`
      background: #FAFAFA;
  
  `;
  const Content = Styled.div`
      width:85%;
      margin:50px auto;
      h1{
          font-family: Oswald;
          font-weight: normal;
          font-size: 34px;
          line-height: 36px;
          color: #E01E38;
      }
      div.container {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
          background:#fff;
          padding:20px 40px ;
          span{
            font-family: PT Serif;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            font-style: italic;
            
          }
      }
      @media (max-width:660px){
        h1{
            font-size: 30px;
            }
            div.container{
              span{
                font-size: 18px;
              }
            }
    }
    @media (max-width:534px){
      margin:30px auto;
        h1{
         font-size: 28px;
         }
         div.container{
          span{
            font-size: 16px;
          }
        }
       }
       @media (max-width:395px){
        padding:16px;
        h1{
         font-size: 26px;
         }
  `;
  const Top = Styled.div`
      h3{
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 30px;
        color: #0A114E;
        margin-bottom:20px;
        text-align:justify;
        @media (max-width:640px){
          font-size: 24px;
        }
        @media (max-width:540px){
          font-size: 20px;
        }
      }
      p{
         text-align:justify;
          margin-bottom:20px;
          font-family: PT Serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
      }
      span{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        font-style: italic;
        @media (max-width:640px){
          font-size: 18px;
        }
        @media (max-width:540px){
          font-size: 16px;
        }
      }
  `;
  