import Header from "./components/Header";
import Footer from "./components/Footer";
import ListOfResearchScholars from "./components/ListOfResearchScholars";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Faculty from "./components/Faculty";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import MainPage from "./components/MainPage";
import News from "./components/News";
import KrishnaIyerChair from "./components/KrishnaIyerChair";
import Nrm_centre from "./components/Madhavamenoncenter";
import Culr from "./components/Culr";
import Optimum from "./components/Optimum";
import Culac from "./components/Culac";
import Alumni from "./components/Alumni";
import Mcc from "./components/Mcc";
import Sap from "./components/Sap";
import Stafflist from "./components/StaffList";
import LibraryStaff from "./components/LibraryStaff";
import BBA from "./components/BBA";
import Masters from "./components/Masters";
import BCom from "./components/BCom";
import ThreeYearLLB from "./components/ThreeYearLLB";
import Extensions from "./components/Extensions";
import Research from "./components/Research";
import PHDProgram from "./components/PHDProgram";
import Icsi from "./components/Icsi";
import GalleryImg from "./components/GalleryImg";
import DirectorMessage from "./components/DirectorMessage";
import OtherCommittees from "./components/OtherCommittees";
import ActivityClub from "./components/ActivityClub";
import { Extension } from "@material-ui/icons";
import SlsLibrary from "./components/SlsLibrary";
import AllGallery from "./components/AllGallery";
import Projects from "./components/ResearchProjects";
import Archives from "./components/Archive";
import NewsArchive from "./components/NewsArchive";
import PhdArchive from "./components/PhdArchive";
import BcomArchive from "./components/BcomArchive";
import BbaArchive from "./components/BbaArchive";
import LlmArchive from "./components/LlmArchive";
import ThreeyearllbArchive from "./components/ThreeyearllbArchive";
import Downloads from "./components/Downloads";

import "../src/App.css";
import SeminarsConferences from "./components/SeminarsConferences";
import WednesdaySeminars from "./components/WednesdaySeminars";
import PublicLawLectures from "./components/PublicLawLectures";
import OtherEvents from "./components/OtherEvents";


import MOU from "./components/MOU";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="all">
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/aboutUs" component={AboutUs} />
            <Route exact path="/research" component={ListOfResearchScholars} />
            <Route exact path="/faculty" component={Faculty} />
            <Route exact path="/contactUs" component={ContactUs} />
            <Route exact path="/news" component={News} />
            <Route exact path="/BBA" component={BBA} />
            <Route exact path="/BCom" component={BCom} />
            <Route exact path="/Masters" component={Masters} />
            <Route exact path="/ThreeyearLLB" component={ThreeYearLLB} />
            <Route exact path="/PHDProgram" component={PHDProgram} />
            <Route path="/gallery" component={GalleryImg} />
            <Route path="/allgallery" component={AllGallery} />
            <Route exact path="/directorMsg" component={DirectorMessage} />
            <Route exact path="/activityClub" component={ActivityClub} />
            <Route exact path="/committees" component={OtherCommittees} />
            <Route exact path="/mcc" component={Mcc} />
            <Route exact path="/culac" component={Culac} />
            <Route exact path="/alumni" component={Alumni} />
            <Route exact path="/staffList" component={Stafflist} />
            <Route exact path="/librarystaff" component={LibraryStaff} />
            <Route exact path="/culr" component={Culr} />
            <Route exact path="/sap" component={Sap} />
            <Route exact path="/icsi" component={Icsi} />
            <Route exact path="/optimum" component={Optimum} />
            <Route exact path="/nrmcentre" component={Nrm_centre} />
            <Route exact path="/krishnaIyer" component={KrishnaIyerChair} />
            <Route exact path="/extension" component={Extension} />
            <Route exact path="/slslibrary" component={SlsLibrary} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/mous" component={MOU} />

            <Route path="/archive" component={Archives} />
            <Route path="/newsarchive" component={NewsArchive} />
            <Route exact path="/phdarchive" component={PhdArchive} />
            <Route exact path="/llmarchive" component={LlmArchive} />
            <Route exact path="/bcomarchive" component={BcomArchive} />
            <Route exact path="/bbaarchive" component={BbaArchive} />
            <Route
              exact
              path="/threeyearllbarchive"
              component={ThreeyearllbArchive}
            />
            <Route exact path="/downloads" component={Downloads} />
            <Route exact path="/seminars" component={SeminarsConferences} />
            <Route
              exact
              path="/wednesdaySeminars"
              component={WednesdaySeminars}
            />
            <Route
              exact
              path="/publicLawLectures"
              component={PublicLawLectures}
            />
            <Route exact path="/otherEvents" component={OtherEvents} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
