import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import Aboutimage from "../Images/aboutusimage.png";
import bgimage from "../Images/aboutbg.svg";
import Varkey from "../Images/Varkey.JPG";
import Markose from "../Images/Markose.jpg";
import ChandraPillai from "../Images/ChandrashekaranPillai.jpg";
import Gopalakrishnan from "../Images/Gopalakrishnan.jpg";
import James from "../Images/James.JPG";
import JosephVadakkethala from "../Images/JosephVadakkethala.jpg";
import Leelakrishnan from "../Images/Leelakrishnan.JPG";
import Rajeev from "../Images/Rajeev.jpg";
import SadasivanNair from "../Images/SadasivanNair.JPG";
import Sebastian from "../Images/Sebastian.jpg";
import ValsammaPaul from "../Images/ValsammaPaul.jpg";
import Chandrashekaran from "../Images/Chandrashekaran.JPG";
import Soman from "../Images/Soman.jpg";
import VSSebastian from "../Images/VSSebastian.jpg";
import Prasannan from "../Images/Prasannan.jpg";


import axios from "axios";

export default function Disclaimer() {
  var w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  console.log(w, "max width");
  const [response, setresponse] = useState([]);
  const [image, setimage] = useState([]);
  const API = process.env.REACT_APP_API;
  useEffect(() => {
    window.scroll(0, 0);
    axios
      .get(`${API}/aboutus/content/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${API}/aboutus/image/view/`)
      .then((res) => {
        console.log(res.data.data);
        setimage(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="image">
        <IMG src={image.image} alt="about image" />
        <DIV className="bg">
          <section>
            <DIVSEC className="divbox">
              {response.map((res) => (
                <div>
                  <h2 id="toper">{res.title} </h2>
                  <p>{res.para1}</p>
                  <p>{res.para2}</p>
                  <p>{res.para3}</p>
                  <p>{res.para4}</p>
                  <p>{res.para5}</p>
                  <p>{res.para6}</p>
                  <p>{res.para7}</p>
                  <p>{res.para8}</p>
                  <p>{res.para9}</p>
                  <p>{res.para10}</p>
                </div>
              ))}
              <h2 id="toper">Former Faculty Members</h2>
              <div className="container">
                <div className="formerRow">
                  <img src={Markose} />
                  <p>Prof. (Dr.) A. T. Markose</p>
                </div>
                <div className="formerRow">
                  <img src={Prasannan} />
                  <p>Prof. (Dr.) R. Prasannan</p>
                </div>
                <div className="formerRow">
                  <img src={Sebastian} />
                  <p>Prof. (Dr.) V. D. Sebastian</p>
                </div>
                <div className="formerRow">
                  <img src={Leelakrishnan} />
                  <p>Prof. (Dr.) P. Leelakrishnan</p>
                </div>
                <div className="formerRow">
                  <img src={JosephVadakkethala} />
                  <p>Prof. Joseph Vadakkethala</p>
                </div>
                <div className="formerRow">
                  <img src={James} />
                  <p>Dr. V. G. James</p>
                </div>
                <div className="formerRow">
                  <img src={Chandrashekaran} />
                  <p>Prof. (Dr.) N. S. Chandrashekaran</p>
                </div>
                <div className="formerRow">
                  <img src={ChandraPillai} />
                  <p>Prof. (Dr.) K. N. Chandrashekaran Pillai</p>
                </div>
                <div className="formerRow">
                  <img src={SadasivanNair} />
                  <p>Prof. (Dr.) G. Sadasivan Nair</p>
                </div>
                <div className="formerRow">
                  <img src={Rajeev} />
                  <p> Prof. (Dr.) D. Rajeev</p>
                </div>
                <div className="formerRow">
                  <img src={Varkey} />
                  <p>Prof. (Dr.) A. M. Varkey</p>
                </div>
                <div className="formerRow">
                  <img src={ValsammaPaul} />
                  <p> Prof. (Dr.) Valsamma Paul</p>
                </div>
                <div className="formerRow">
                  <img src={VSSebastian} />
                  <p>Dr. V. S. Sebastian</p>
                </div>
                <div className="formerRow">
                  <img src={Soman} />
                  <p>Prof. (Dr.) N. S. Soman</p>
                </div>
                <div className="formerRow">
                  <img src={Gopalakrishnan} />
                  <p> Prof. (Dr.) N. S. Gopalakrishnan</p>
                </div>
              </div>
            </DIVSEC>
          </section>
        </DIV>
      </div>
    </div>
  );
}

const DIVSEC = Styled.div`
.container{
  display:flex !important;
  flex-wrap:wrap;
  justify-content:space-between;
  max-width:900px;
  margin:0 auto;
  margin-top:34px;
  

}
.formerRow{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
  width:30%;
  @media (max-width:624px){
    width:45%;
   }
  P{
    padding:0;
    text-align:center;
  }
  
  img{
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
}
h2{
  font-family: Oswald;
  font-style: normal;
  font-weight:normal;
  font-size: 34px;
  line-height: 36px;
  color: #E01E38;
  text-align: center;
  margin-top:20px;
  margin-bottom:0px;
  }
  @media (max-width:768px){
    h2{
      font-size: 30px;
      line-height: 32px;
    }
  }
  @media (max-width:600px){
    h2{
      font-size: 26px;
      line-height: 0px;
    }
  }
  @media (max-width:560px){
    h2{
      font-size: 24px;
      line-height: 0px;
    }
  }
  @media (max-width:480px){
    h2{
      font-size: 20px;
      line-height: 0px;
    }
  }
  @media (max-width:410px){
    h2{
      font-size: 18px;
      line-height: 0px;
    }
  }
`;
const DIV = Styled.div`

    width: 100%;
    background-image: url(${bgimage});
    background-repeat: no-repeat;
    background-position: 1px;
    background-size: cover;
    
    section{
      position: relative;
      display:flex;
      justify-content:center;
      align-items:center;
    }
    p{
      font-family: PT Serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      text-align: justify;
      color: #000000;
      padding:0px 30px 0px 30px;
      display:inline-block;
      margin-bottom:0px;
      
  }
     
        div.divbox{
            background: white;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
            position: relative;
            padding: 20px;
            width: 85%;
            margin:-230px auto 0;
            z-index:100;
            background:white;
            margin-bottom:150px;
            @media(max-width:540px){
              width:100%;
              margin-top: -50px;
            @media(max-width:487px){
              width: 92%;
            @media(max-width:457px){
              width: 91%;
            }
            @media(max-width:400px){
              width: 90%;
            }
        }
        

    @media (max-width:768px){
      div.divbox{
        padding: 15px;
        
      }
      p{
        font-size: 18px;
        padding:0px 20px 0px 20px;
        line-height: 20px;
      }
    }  
     @media (max-width:600px){
       div.divbox{
        padding: 10px;
        margin:-120px auto 0;
     }
      p{
        font-size: 16px;
       padding:0px 10px 0px 10px;
       line-height: 20px;
     }
     }  
    @media (max-width:480px){
      div.box{
         margin:-100px auto 0;
       }
       p{
         font-size: 12px;
        padding:0px 10px 0px 10px;
         line-height: 16px;
       
       }
    }  
    @media (max-width:410px){
      div.box{
         margin:-0px auto 0;
       }
       p{
         font-size: 10px;
        padding:0px 10px 0px 10px;
         line-height: 14px;
       
       }
    }    
`;
const IMG = Styled.img`
     width: 100%;
     z-index:1;
     height: 60vh;
`;
