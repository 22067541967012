import axios from "axios";
import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Footer() {
  const [response, setresponse] = useState([]);
    const API = process.env.REACT_APP_API; 
  useEffect(() => {
    axios
      .get(`${API}/count/increament/`)
      .then((res) => {
        console.log(res.data.total, "count");
        setresponse(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <FooterSection>
        <Middle>
          <MidLeft>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/aboutUs">About Us</Link>
              </li>
            </ul>
            <ul>
              <li>
                <a>Research</a>
              </li>
              <li>
                <Link to="/sap">UGC SAP</Link>
              </li>
              <li>
                <Link to="/krishnaIyer">Chair on Human Rights</Link>
              </li>
              <li>
                <Link to="/nrmcentre">Interdisciplinary Centre</Link>
              </li>
              <li>
                <Link to="/projects">Research Activities</Link>
              </li>
            </ul>
            <ul>
              <li>
                <a>Courses</a>
              </li>
              <li>
                <Link to="/PHDProgram">Ph. D</Link>
              </li>
              <li>
                <Link to="/Masters">LLM</Link>
              </li>
              <li>
                <Link to="/BBA">BBA LLB</Link>
              </li>
              <li>
                <Link to="/BCom">BCom LLB</Link>
              </li>
              <li>
                <Link to="/ThreeyearLLB">3 yr LLB</Link>
              </li>
            </ul>
            <ul>
              <li>
                <a>Staff</a>
              </li>
              <li>
                <Link to="/faculty">Faculty</Link>
              </li>
              <li>
                <Link to="/staffList">Office Staff</Link>
              </li>
              <li>
                <Link to="/librarystaff">Library Staff</Link>
              </li>
            </ul>
            <ul>
              <li>
                <a>Library</a>
              </li>
              <li>
                <Link to="/slslibrary">SLS</Link>
              </li>
              <li>
                <a target="blank" href="http://library.cusat.ac.in/">University</a>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/contactUs">Contact us</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/news">News</Link>
              </li>
            </ul>
          </MidLeft>
          <MidRight>
            <ul className="bottom">
              <li>
                Total Visitors
                <span>{response.total}</span>
              </li>
            </ul>
          </MidRight>
        </Middle>
      </FooterSection>
      <Bottom>
      <p>
            Note: The information uploaded on the website of SLS is subject to
            the condition that although every care has been taken while
            uploading the correct informations, yet in case of any
            inconsistency or error, the version of SLS Office and Cochin
            University shall prevail.
          </p>
          <label>
            Copyright © 2019 All Rights Reserved School of Legal Studies, Cochin
            University of Science and Technology, Kerala, India
          </label>
          <End>DESIGNED, DEVELOPED AND MAINTAINED by <a href="https://techbyheart.in/" target="blank"> techbyheart</a></End>
        
      </Bottom>
    </div>
  );
}
const FooterSection = Styled.section`
  background: linear-gradient(91deg, rgba(220, 42, 42, 0.072) 24.19%, rgba(182, 0, 0, 0.9) 71.18%), #BB0000;
  width:100%;
  
`;

const Middle = Styled.div`
  display:flex;
  padding-top:30px;
  width: 100%;
  justify-content:space-between;
`;

const MidRight = Styled.div`
  display:flex;
  flex-direction: column-reverse;
  justify-content:space-between;
  ul.bottom{
      display:flex;
      flex-direction:row-reverse;
      li{
        display: flex;
        flex-direction:column;
        justify-content: end;
        align-items: normal;
        font-family: PT Serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 10px 20px;
        background: linear-gradient(0deg, #121212, #121212), #FFFFFF;
        @media screen and (max-width:940px){
          padding: 8px 14px;
          font-size:12px;
          font-weight: 500;
        }
        @media screen and (max-width:960px){
          font-weight: 500;
        }
        @media screen and (max-width:882px){
          padding: 7px 12px;
          font-size:11px;
          font-weight: 500;
        }
        @media screen and (max-width:756px){
          padding: 6px 10px;
          font-size:10px;
          font-weight: 500;
        }
        @media screen and (max-width:676px){
          padding: 6px 8px;
          font-size:9px;
          font-weight: 500;
        }
        @media screen and (max-width:676px){
          padding: 4px 6px;
          font-size:8px;
          font-weight: 500;
        }
        @media screen and (max-width:514px){
          ul.bottom{
            width:20%;
            li{
              
              font-size:8px;
              font-weight: 500;
            }
          }
         
        }
      }`
     ;

const MidLeft = Styled.div`
  width:85%;
  display:flex;
  justify-content:space-around;
  ul{
    display:flex;
    flex-direction:column;
    li{
      list-style:none;
      margin-bottom: 20px; 
      
        a{
          text-decoration:none;
          font-family: PT Serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 17px;
          color: #FFFFFF;
        }
        &:first-child{
          a{
            font-weight: 600; 
            font-size: 16px;
            letter-spacing:0.5px;
          }
        }
      }
    }
    @media (max-width:1000px){
      ul{
        li{
          a{
            font-weight:500;
          }
        }
      }
    }
    @media (max-width:960px){
      ul{
        li{
          a{
            font-size:14px;
          }
        }
      }
    } 
    @media (max-width:820px){
      flex-wrap:wrap;
      ul{
          width:21%;
         li{
            a{
             font-size:14px;
            }
            &:first-child{
              a{
                font-weight:600;
                font-size: 14px;
                
              }
            }
         }
      }
    }
   `;

const Bottom = Styled.div`
height: 110px;
background: #8B0000;  
  display:flex;
  flex-direction:column;
  label{
    font-family: PT Serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    margin-left:2%;
  }
  p{
    font-family: PT Serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left:2%;
    color: #FFFFFF;
  }
  @media screen and (max-width:1780px){
    height:130px;
  }
  @media screen and (max-width:1004px){
    height:110px;
    p{
      font-size:12px;
    }
  }
  @media screen and (max-width:880px){
    height:100px;
   label{
     font-size:12px;
   }
   p{
     font-size:11px;
   }
  }
  @media screen and (max-width:768px){
     height:90px;
     label{
       font-size:10px;
       font-weight:500;
     }
     p{
       font-size:10px;
     }
  }
  @media screen and (max-width:740px){
    height:110px;
    label{
      font-size:10px;
      font-weight:500;
    }
    p{
      font-size:10px;
    }
 }
 @media screen and (max-width:599px){
  height:120px;
  label{
    font-size:10px;
    font-weight:500;
  }
  p{
    font-size:10px;
  }
}
@media screen and (max-width:460px){
  height:110px;
  label{
    font-size:10px;
    font-weight:500;
  }
  p{
    font-size:10px;
  }
}
@media screen and (max-width:412px){
  height:120px;
}
@media screen and (max-width:454px){
  height:125px;
}
@media screen and (max-width:340px){
  height:130px;
}
`;
const End = Styled.div`
font-family: PT Serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 15px;
text-transform: uppercase;
color: #FFFFFF;
display:flex;
align-items:center;
justify-content:left;
margin-left:2%;
margin-top:8px;
margin-bottom:12px;
a{
  color:#fff;
  text-decoration:none;
  padding-left:5px;
}
@media screen and (max-width: 880px){
  font-size:14px;
  font-weight: 500;
}
@media screen and (max-width: 768px){
  font-size:12px;
  font-weight:500;
  margin-bottom:16px;
}
@media screen and (max-width: 412px){
  font-size:10px;
  margin-bottom:18px;
}`

