import React ,{useEffect} from "react";
import Styled from "styled-components";
import Adminimg from "../Images/NewsLetter.svg";

export default function Optimum() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <DIV>
      <BOX>
        <h1>Optimus Omnium – SLS News Letter </h1>

        <COLOR>
          <TOP>
            <p>
              {" "}
              The Optimus Omnium is a biannual newsletter of the School of Legal
              Studies which reflects on the important events that took place in
              the School of Legal Studies during the preceding 6 months. This
              includes messages from the patrons of the University, the
              Director, Office bearers of Alumni Association, and the Dean. The
              Optimus Omnium takes within its sweep the achievements and
              recognitions of all the members of the SLS family called the
              students, the members of faculty, the Office and the Library
              Staff, and the alumni during the relevant time. The scribbling’s
              of students, members of faculty, and members of alumni will occupy
              some pages of Optimus Omnium, which will be critical evaluation of
              the contemporary issues in Law, Commerce and Management. These
              writing will include case comments, response to contemporary legal
              issues, comment on new legislations, amendments, comments on
              annual financial statements, stock markets, share markets, and
              other contemporary issues in Commerce and Management.
              Brainstorming squiggling on these subjects from other eminent
              personalities will also be a distinguishing feature of Optimus
              Omnium.  
            </p>
            
          </TOP>
          <a href="/downloads">Downloads</a>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const DIV = Styled.div`

background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
`;
const COLOR = Styled.div`
background:white;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
padding:20px 40px;
@media (max-width:368px){
  padding: 10px;
}

`;

const BOX = Styled.div`
   
    width:85%;

   
    margin:50px auto;
 h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    color: #E01E38;
    display:flex;
 }
 @media (max-width:660px){
  h1{
      font-size: 30px;
      }
}
@media (max-width:534px){
margin:30px auto;
  h1{
   font-size: 28px;
   }
 }
 @media (max-width:395px){
  padding:16px;
  h1{
   font-size: 26px;
   }
 
`;

const TOP = Styled.div`

     p{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-align:justify;
        @media (max-width:640px){
          font-size: 18px;
        }
        @media (max-width:540px){
          font-size: 16px;
        }
     }

    

`;
