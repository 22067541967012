import React,{ useState,useCallback, useEffect } from 'react'
import Styled from 'styled-components'
import Arrow from '../Images/seeall.svg'
import {Link,useLocation} from 'react-router-dom';
import axios from "axios";
import BackButton from '../Images/leftarrow.svg'

export default function AllGallery() {

    const location = useLocation();
    let urls = location.pathname.split("/");
    const [galleryId, setGalleryId] = useState(urls[2]);
    const [response, setresponse] = useState([]);
    const API = process.env.REACT_APP_API;

    
    useEffect(() => {
        window.scroll(0,0);
        axios
    .get(`${API}/posts/postyearwiseview/${galleryId}`)
          .then((res) => {
            console.log(res.data.data);
            setresponse(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    return (
        <div>
     
        <Container>
      
            <h4> <span><img src={BackButton} alt="back-button"  onClick={()=>window.history.back()}/> </span>Gallery</h4>
            
            {response.map((res)=>(
            <Event>
                <h3>{res.title}</h3>
                <Link style={{textDecoration:"none"}} to={`/gallery/${res.id}`}>
                <Border className="border">
                    <Right>
                        <img src={res.image1} alt="post image 1"/>
                    </Right>

                    <Left>
                        {res.image2==null?'':
                        <div >
                            <img src={res.image2} alt="post image 2"/>
                        </div>}
                        {res.image3==null?'':
                        <div >
                            <img src={res.image3} alt="post image 3"/>
                        </div>}
                    </Left> 
                </Border>
                <p>{res?.discription?.slice(0,150)}</p>
                <Link to={`/gallery/${res.id}`}><span>SEE ALL</span><img src={Arrow} alt="arrow-imgae"/></Link>
                </Link>
            </Event>
            ))}
            
        </Container>
        </div>
    )
}

const Container = Styled.div`
    width:80%;
    margin:50px auto;
    h4{
        font-family: "Oswald";
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 36px;
        color: #000000;
        img{
            margin-right:50px;
            cursor:pointer;
        }
    }
    span {
        display:inline-block;
        margin-right:20px;
    }
    p{
        text-decoration:none;
        color:black;
    }
`;
const Border = Styled.div`
    border-top:1px solid rgba(0, 0, 0, 0.16);
    display : flex;
    margin-bottom:30px;
    gap:20px;
    img{
        display:block;
        width: 100%;
        object-fit:cover;
      
    }
`;
const Right =Styled.div`
    margin-top:30px;
    width:65%;
    height:270px;
    img{
        height:270px;
    }
`;
const Left = Styled.div`
    margin-top:30px;
    width:35%;
    height:250px;
    display:flex;
    justify-content:space-between;
    gap : 20px;
    flex-direction:column;
    div{
        
        img{
            display:block;
            height:125px;
        }
    }
`;
const Event = Styled.div`
    margin-bottom:80px;
    h3{
        font-size:25px;
    }
    p{

    }
`;