import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import axios from "axios";


function ResearchProjects() {
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;
  useEffect(() => {
    window.scroll(0,0);
    axios
      .get(`${API}/research_project/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Div>
      <Box>
        <h1>Research Projects</h1>
        <Container>
        {response.map((res,index) => (
          <MainDiv>
          <h2>{index+1}.{res.title}</h2>
          <DIV>
            <p className="number">Agency & Scheme</p>
            <p className="colon">:</p>
            <p className="medium">{res.agency}</p>
          </DIV>
          <DIV>
            <p className="number">Year</p>
            <p className="colon">:</p>
            <p className="medium">{res.year} </p>
          </DIV>
          <DIV>
            <p className="number">Principal Investigator</p>
            <p className="colon">:</p>
            <p className="medium">{res.principle_investigation}</p>
          </DIV>
          <DIV>
            <p className="number">Discription</p>
            <p className="colon">:</p>
            <p className="medium">{res.discription}</p>
          </DIV>
          </MainDiv>
          ))}
        </Container>
      </Box>
    </Div>
  );
}

export default ResearchProjects;

const Div = Styled.div`
    background: #FAFAFA;
    display:flex ;
    justify-content:center;
    align-items: center;
    width:100%;
 `;
const Box = Styled.div`
    width:85%;
    margin: 50px auto;
    h1{
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 36px;
        color: #E01E38;
        @media(max-width:860px){
          font-size: 32px;
        }
        @media(max-width:768px){
          font-size: 30px;
        }
        @media(max-width:457px){
          font-size: 28px;
        }
    }
 `;

 const MainDiv = Styled.div`
  margin-bottom:100px;
 `;
const Container = Styled.div`
    padding:20px 40px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    
    @media(max-width:375px){
      padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    }
    h2{
        font-family: Oswald;
        font-style: normal;
        font-weight:500;
        font-size: 26px;
        line-height: 36px;
        color: #0A114E;
        margin-bottom:25px;
        @media(max-width:772px){
          font-size: 23px;
        }
        @media(max-width:700px){
          font-size: 21px;
        }
        @media(max-width:600px){
          font-size: 19px;
         
        }
      
    }
 `;

const DIV = Styled.div`
 display:flex;
 align-items:center;
 margin-bottom:14px;
 p.number{
    width:30%;
    font-family: PT Serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
 }
 p.colon{
    width:8%;
 }
 p.medium{
    width:90%;
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
 }
 @media(max-width:1080px){
  p.number{
    width:24%;
  }
  p.colon{
    width:6%;
 }
 p.medium{
  width:70%;
 }
 }
 @media(max-width:900px){
  p.number{
    width:28%;
  }
  p.colon{
    width:6%;
 }
 p.medium{
  width:62%;
 }
 }
 @media(max-width:768px){
  p.number{
    width:35%;
  }
  p.colon{
    width:6%;
 }
 p.medium{
  width:72%;
 }
 }
 @media(max-width:680px){
  p.number{
    width:55%;
  }
  p.colon{
    width:4%;
 }
 p.medium{
  width:85%;
 }
 }
 @media(max-width:582px){
  p.number{
    width:50%;
  }
  p.colon{
    width:4%;
 }
 p.medium{
  width:65%;
 }
 }
 @media(max-width:440px){
  p.number{
    width:50%;
  }
  p.colon{
    width:4%;
 }
 p.medium{
  width:65%;
 }
 }
 @media(max-width:411px){
  p.number{
    width:40%;
  }
  p.colon{
    width:8%;
 }
 p.medium{
  width:60%;
 }
 }
 @media(max-width:860px){
  p.number{
    font-size: 18px;
  }
  p.medium{
    font-size: 18px;
  }
 }
 @media(max-width:768px){
  p.number{
    font-size: 16px;
  }
  p.medium{
    font-size: 16px;
  }
 }
 @media(max-width:411px){
  p.number{
    font-size: 14px;
  }
  p.medium{
    font-size: 14px;
  }
 }
 `;
