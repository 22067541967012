import React,{useEffect} from 'react'
import Styled from 'styled-components'
export default function AboutUs() {
    useEffect(() => {
        window.scroll(0,0)
      }, []);
    return (
        <>
        <BG id="contact">
            <DIVMAP>

                <DIV className="google-map-code">
                <iframe src="https://maps.google.com/maps?q=10.043797639405279,%2076.32791461327217&t=&z=15&ie=UTF8&iwloc=&output=embed"> </iframe>
                </DIV>
                <DIVSEC className="content">
                    <h2>Contact Us</h2>
                    <h4> School of Legal Studies <br />Cochin University of Science and Technology<br />Cochin University P.O.<br />Kalamassery, Kochi<br/>Kerala- 682022<br />India
                    <br /><br />0484 257 5465<br/> Mob: +91 9383445550<br/>slcusat@gmail.com <br/>sls@cusat.ac.in</h4>
                </DIVSEC>      
            </DIVMAP>
        </BG>
        </>
    )
}
const DIV =Styled.div`

    iframe{
        width: 389px;
        height: 424px;
        style:{{border:0}};
        allowfullscreen:"";
        aria-hidden:"false";
        tabindex:"0";
      @media(max-width:960px){
        width:360px;
        height:380px;
      }
      @media(max-width:846px){
        width:320px;
        height:360px;
      }
      @media(max-width:540px){
        width:260px;
        height:260px;
      }
    }
`;
const DIVMAP =Styled.div`

display: flex;
justify-content: center;
align-items: center;
margin: 100px auto;
@media (max-width:720px){
    flex-direction:column;
}


    h2{
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        text-align: center;
        margin-top: 15px;
       color: #E01E38;
    }
    h4{
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
    }
    @media(max-width:540px){
        h4{
            font-family: Oswald;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
        }
        h2{
            font-family: Oswald;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            margin-top: 20px;
           
        }
      }
  
`;
const DIVSEC =Styled.div`
    width: 374px;
    height: 429px;
    background: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    @media(max-width:960px){
        height: 380px;
    }
    @media(max-width:846px){
        height: 357px;
        width: 320px;
    }
    @media(max-width:540px){
        height: 357px;
        width: 265px;
    }`;


const BG =Styled.div`

     background: #FAFAFA;
     display: flex;
    justify-content: center;
    align-items: center;
`;