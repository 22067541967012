import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import Frame1 from "../Images/Frame 1.jpg";
import Frame2 from "../Images/Frame 2.jpg";
import Frame3 from "../Images/Frame 3.jpg";
import Frame4 from "../Images/Frame 4.jpg";
import Frame5 from "../Images/Frame 5.jpg";
import Frame6 from "../Images/frame9.jpg";
import Frame7 from "../Images/frame10.jpg";
import Frame8 from "../Images/Frame 8.jpg";

import axios from "axios";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "./Style.css";

export default function Spotlight() {
  const settings = {  
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    arrows: true,
    autoplay:true
    
  };
  const [responses, setResponses] = useState([]);
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;


  useEffect(() => {
    axios
      .get(`${API}/news/titleview/`)
      .then((res) => {
        console.log(res.data.data, "first");

        setresponse(res.data.data);
      })
      axios
      .get(`${API}/home/view/`)
      .then((res) => {
        console.log(res.data.data, "second");

        setResponses(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <OVERLAY>
      <Image>
        <Slider {...settings}>
        {responses.map((res) => (
          <div>
            <CarouselImage>
              <img src={res.image} />
                <div class="carousel">
                  <h1>{res.quote}</h1>
                </div>
           
            </CarouselImage>
          </div>
          ))}
          </Slider>
      </Image>
      <DIV>
        <BOX>SLS TODAY</BOX>
        <Marquee>
          {response.map((res) => (
            <a href="/news">
              <Span>*</Span>
              {res.title}
            </a>
          ))}
        </Marquee>
      </DIV>
    </OVERLAY>
  );
}

const Image = Styled.div`
width:100%;

img{
    display:block;
    width:100%;
    z-index:1;   
}
`;
const OVERLAY = Styled.div`
z-index: 2 ;
`;
const DIV = Styled.div`
  width :100%;
  background: yellow;
  display:flex;

  `;
const BOX = Styled.div`
  height: 32px;
  width:10%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #8B0000;
  font-family:PT Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #FFFFFF;
  @media screen and (max-width:1280){
    font-size: 18px;
  }
  @media screen and (max-width:1176px){
    font-size: 16px;
    height:30px;
  }
  @media screen and (max-width:940px){
    font-size: 14px;
    height:28px;
  }
  @media screen and (max-width:825px){
    font-size: 12px;
    height:26px;
  }
  @media screen and (max-width:710px){
    width:12%;
    height:24px;
  }
  @media screen and (max-width:599px){
    width:14%;
    height:22px;
    font-size: 9px;
  }
  @media screen and (max-width:400px){
    width:15%;
    font-size: 8px;
    
  }`;

const Span = Styled.span`
  color:#B60305;
  margin-left:20px;
  
 `;
const Marquee = Styled.marquee`
  height:28px;
  font-family: PT Serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color:#000;
  display:inline-block;
  width:100%;
  padding-top:4px;
  a{
    text-decoration:none;
  }
@media screen and (max-width:1280px){
  font-size: 17px;
  line-height: 22px;
}
@media screen and (max-width:1176px){
  font-size: 16px;
  line-height: 20px;
  height:26px;
}
@media screen and (max-width:940px){
  font-size: 14px;
  line-height: 18px;
  height:24px;
}
@media screen and (max-width:825px){
  font-size: 12px;
  line-height: 16px;
  height:22px;
}
@media screen and (max-width:710px){
  font-size: 10px;
  line-height: 14px;
  height:20px;
}
@media screen and (max-width:599px){
  font-size: 9px;
  line-height: 12px;
  height:18px;
}
@media screen and (max-width:400px){
  font-size: 9px;
  line-height: 12px;
  height:16px;
}`;
const CarouselImage = Styled.div`
  position:relative;

    img{
      background: rgba(0, 0, 0, 0.3);
      width:100%;
      // height:600px;
      // @media(max-width:768px){
      //   height:400px;
      // }
      // @media(max-width:540px){
      //   height:300px;
      // }
      }
      div.carousel{
        position:absolute;  
        top: 0%;
        font-size:24px;
        color:white;
        text-align:center;
        width:100%;
        background: rgba(0, 0, 0, 0.3);
        height:100%;
        h1{
          margin: 8% auto 0% auto;
          font-family: PT Serif;
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 56px;
          color: white;
          width:80%;
        }
        h4{
          font-family: PT Serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: white;
          padding: 10px;
          background: #3D352C;
          display: inline-block;
  
        }
        
      }
      
      @media(max-width:1295px){
        div.carousel{
          h1{
           
              font-size: 38px;
              line-height:50px;
            
          }
          h4{
            font-size: 22px;
          }
        }
      }
      @media(max-width:1240px){
        div.carousel{
          h1{
            font-size: 36px;
            line-height:48px;
          }
          h4{
            font-size: 20px;
          }
          
        }
      }
      @media(max-width:1165px){
        div.carousel{
          h1{
            font-size: 34px;
            line-height:44px;
          }
          h4{
            font-size: 18px;
            padding:8px;
          }
          
        }
      }
      @media(max-width:1100px){
        div.carousel{
          h1{
            font-size: 28px;
            line-height:40px;
          }
          h4{
            font-size: 16px;
            padding:6px;
          }
          
        }
      }
      @media(max-width:906px){
        div.carousel{
          h1{
            font-size: 25px;
          }
          h4{
            font-size: 12px;
            padding:4px;
          }
          
        }
      }
      @media(max-width:810px){
        div.carousel{
          h1{
            font-size: 22px;
          }
          h4{
            font-size: 8px;
            padding:3px;
          }
          
        }
      }
      @media(max-width:768px){
        div.carousel{
          display:none;
        }
      }
 `;
