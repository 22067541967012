import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Styled from "styled-components";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import { Link, useLocation } from "react-router-dom";
import BackButton from "../Images/leftarrow.svg";

function GalleryImg() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const API = process.env.REACT_APP_API;

  const location = useLocation();
  let urls = location.pathname.split("/");
  const [galleryId, setGalleryId] = useState(urls[2]);
  const [response, setresponse] = useState([]);

  // let photoo = [];
  const [photoo, setphotoo] = useState([]);
  //
  useEffect((id) => {
    axios
      .get(`${API}/gallery/eventview/${galleryId}`)
      .then((res) => {
        setresponse(res.data.data);
        console.log("response",res.data.data)
        setphotoo(
          res.data.data.map((img) => ({
            src: img.image,
            width: 4,
            height: 2,
            objectFit:"cover",
          }))
        );
        console.log(photoo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <IMAGE>
        <HEADER>
         
        <img src={BackButton} alt="back-button" onClick={()=>window.history.back()}/>{" "}
        <h3>    Gallery    </h3>
          
        
      
        </HEADER>
        {/* {photoo.map((ph) => (
          <img src={ph.src} />
        ))} */}
        <Gallery photos={photoo} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel 
                currentIndex={currentImage}
                views={photoo.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </IMAGE>
    </div>
  );
}
const IMAGE = Styled.div`
padding: 20px 100px;

`;
const HEADER = Styled.div`

display: flex;
    img{
      margin-right:50px;
      cursor:pointer;
    }
    h3{
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: #0A114E;
      
    }
`;
export default GalleryImg;
