import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import Pdf from "../Images/pdf.png";
import axios from "axios";
import { Link } from "react-router-dom";

function BBA() {
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    window.scroll(0, 0);
    axios
      .get(`${API}/course/bba_llb/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Container>
        <Box>
          <h1>BBA. LLB (Hons.)</h1>
          <Bottom>
            <p className="top">
              A businessman, an entrepreneur, or a company owner cannot help
              knowing the details of all Laws that govern him. An Advocate must
              go beyond just the Law governing the companies, or firms, or
              business to help his clients. To have a deep understanding of Law
              and business thus is a crown for a businessman and an Advocate.
              This dual degree course consists of business related subjects such
              as Business Ethics, Financial Management, Human Resource
              Management etc., and the core Law subjects. This gives the
              students an immense opportunity to have the knowledge of Law with
              a business background, or the knowledge of Business
              administration, with a Law background. During the last two
              semesters the course is specialized on different branches of a
              single subject like Corporate Governance through special
              electives. This gives the students an opportunity to specialize in
              this subject as in a Post Graduate programme as the papers are of
              Post-Graduation standard. This gives the students a deep knowledge
              about the legal aspects of business administration, and the
              Business administration aspect of Law. This course thus gives the
              double benefit of having graduated two subjects.{" "}
            </p>
            <Div>
              <p className="number">Numbers of Seats</p>
              <p className="colon">:</p>
              <p className="medium">30 + Supernumerary</p>
            </Div>
            <Div>
              <p className="number">Duration</p>
              <p className="colon">:</p>
              <p className="medium">5 years (Ten Semesters) </p>
            </Div>
            <Div>
              <p className="number">Instruction Medium</p>
              <p className="colon">:</p>
              <p className="medium">English</p>
            </Div>
            <Div>
              <p className="number">Curriculum</p>
              <p className="colon">:</p>
              <p className="medium">
                The subjects for the course shall be prescribed by the Cochin
                University of Science and Technology from time to time in
                accordance with the rules of the Bar Council of India.
              </p>
            </Div>
            <H6>Minimum Qualifications Required for Applying:</H6>
            <p className="end">
              At the time of admission the candidate should have passed plus two
              examination with at least 60% marks including languages in Science
              group/Commerce group or with at least 55% marks including
              languages in Arts/Humanities stream. Candidates belonging to SC/ST
              communities or belonging to SEBC communities with a pass in the
              plus two examination with 50% percent marks are entitled to seek
              admission.
            </p>
            <Div>
              <p className="number">Admission Procedure</p>
              <p className="colon">:</p>
              <p className="medium">
                Admission through Common Entrance Test (CUSAT - CAT) **
              </p>
            </Div>
            <p className="disclaimer">
              ** Subject to changes made to these regulations from time to time
              by the University.
            </p>
            {response?.map((res) => (
              <>
                <a href={res.file} target="_blank">
                  <img src={Pdf} alt="pdf" />
                  <h3>{res.file_name}</h3>Read more
                </a>

                <Link to="/phdarchive">
                  <p className="end">Click here to view archived files</p>
                </Link>
              </>
            ))}
          </Bottom>
        </Box>
      </Container>
    </div>
  );
}

export default BBA;

const H6 = Styled.h6`
font-family: PT Serif;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;
margin:0px;
color: #000000;
@media screen and (max-width:860px){
    font-size:18px;
 }
 @media screen and (max-width:600px){
    font-size:16px;
 }
`;
const Div = Styled.div`
display:flex;
   p.number{
       
        font-family: PT Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        width:20%;
   }
   p.colon{
    width:8%;
  }
  p.medium{
    font-size: 20px;
    line-height: 24px;
      width:85%;
      text-align:justify;
      font-family: PT Serif;
  }
  @media screen and (max-width:1400px){
     p.number{
         width: 24%;
     }
     p.medium{
     }
  }
  @media screen and (max-width:1200px){
    p.number{
        width: 26%;
    }
 }
 @media screen and (max-width:1124px){
    p.number{
        width: 30%;
    }
 }
 @media screen and (max-width:1024px){
    p.number{
        width: 32%;
    }
 }
 @media (max-width:860px){
    p.number{
        font-size:18px;
    }
    p.medium{
        font-size:18px;
    }
}
@media (max-width:640px){
  p.number{
      font-size:16px;
  }
  p.medium{
      font-size:18px;
  }  
}
@media (max-width:600px){
  p.number{
      font-size:15px;
  }
  p.medium{
      font-size:16px;
  }  
}
@media (max-width:540px){
  p.number{
      width:35%;
  }
  p.medium{
     width:50%;
  }  
  p.colon{
      width:10%;
  }
}
@media (max-width:540px){
  p.number{
      width:35%;
  }
  p.medium{
     width:50%;
  }  
  p.colon{
      width:10%;
  }
}
@media (max-width:410px){
  p.number{
      width:40%;
  }
  p.medium{
     width:40%;
  }  
  p.colon{
      width:12%;
  }
}
`;

const Container = Styled.div`
display:flex;
background: #FAFAFA;
align-items:center;`;

const Box = Styled.div`
width:85%;
margin:50px auto;


p.top{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
}
p.disclaimer{
    font-family: PT Serif;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align:justify;
    color: #000000;
  }
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight:500;
    font-size: 32px;
    line-height: 36px;
    color: #E01E38;

    
}
@media (max-width:860px){
    h1{
        font-size: 28px;
    }
    p.top{
        font-size: 18px; 
    }
   
    p.disclaimer{
        font-size: 18px;
    }
}
@media (max-width:640px){
    h1{
        font-size: 26px;
       
    }
}
@media (max-width:600px){
    margin-top:30px;
    margin-bottom:30px;
    h1{
        font-size: 26px;
        
    }
    p.top{
        font-size: 16px; 
    }
    p.disclaimer{
        font-size: 16px;
    }`;
const Bottom = Styled.div`
padding:20px 40px ;
background: #fff;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
img{
    width:70px;
    height:75px;
}
p.end{
    text-align:justify;
    font-family: PT Serif;
    font-size: 20px;
    line-height: 24px;
    text-decoration:none;
    color : #000;
}
@media (max-width:860px){
    padding:20px 30px 40px 30px;   
}
@media (max-width:860px){
    p.end{
        font-size: 18px;
    }
}
@media (max-width:640px){
    p.end{
        font-size: 16px;
    }
}
`;
