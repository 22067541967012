import React, { useEffect } from "react";
import Styled from "styled-components";
import Culacimg from "../Images/CULACImage.JPG";
export default function Culac() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <DIV>
      <BOX>
        <h1>The Cochin University Legal Aid Clinic-School of Legal Studies</h1>

        <COLOR>
          <TOP>
            <p className="above">
              The Cochin University Legal Aid Clinic (CULAC) was started in the
              School of Legal Studies, with the laudable object of providing
              free Legal Aid to the community and spreading awareness about the
              various laws which the general public may have to confront in
              their day to day life. Since its inception CULAC has been actively
              engaging in conducting various programmes in various spheres of
              the society. The most recent development is the Tele Legal Aid
              Clinic which is being launched in collaboration with the Ernakulam
              District Legal Services Authority with a view to provide legal aid
              with remote access which operates on a virtual interactive
              platform.
            </p>

            <p className="">
              The CULAC has at present about 140 members who serve as para legal
              volunteers. The activities of the CULAC range from creating
              awareness about prevention of drug abuse, anti-human trafficking,
              anti-ragging, participation in lok adalaths where the students
              have a hands on experience for resolving disputes of local people
              and facilitate a settlement thereby preventing the disputes from
              reaching the courts. The CULAC also conducts Webinars on current
              issues of legal relevance. The CULAC plans to expand its future
              activities such that the common man especially the vulnerable gets
              justice at his door step.
            </p>
            <a href="/downloads">Downloads</a>
          </TOP>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const DIV = Styled.div`
  
  background: #FAFAFA;
  display:flex ;
  justify-content:center;
  align-items: center;
  width:100%;
  `;
const COLOR = Styled.div`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  background:white;
  padding: 20px 40px;
  @media (max-width:368px){
    padding: 10px;
}
  `;
const BOX = Styled.div`
     
    width:85%;
    margin:50px auto;
   h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 36px;
    color: #E01E38;
    display:flex;
   }
   @media (max-width:660px){
    h1{
        font-size: 30px;
        }
}
@media (max-width:534px){
  margin:30px auto;
    h1{
     font-size: 28px;
     }
   }
   @media (max-width:395px){
    padding:16px;
    h1{
     font-size: 26px;
     }
  `;

const TOP = Styled.div`
  
       p{
          font-family: PT Serif;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          text-align:justify;
          @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
        }
  `;
