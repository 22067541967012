import React from "react";
import Styled from "styled-components";
import culac from "../Images/Logor.jpeg";
import alumni from "../Images/Alumni.svg";
import Committee from "../Images/Committee.svg";
import mcc from "../Images/Mcc.svg";
import activitiesclub from "../Images/ActivityClub.svg";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function Extensions() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 672,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  console.log(w, "width");
  return (
    <Main>
      <H1>Extension Activities</H1>
      {/* {w < 960 ? ( */}
      <Slider {...settings}>
        <Divs>
          <Link to="/culac">
            <div className="border">
              <Divs1>
                <img src={culac} alt="" />
              </Divs1>
              <a href="#">CULAC</a>
            </div>
          </Link>
        </Divs>
        <Divs>
          <Link to="/alumni">
            <div className="border">
              <Divs1>
                <img src={alumni} alt="" />
              </Divs1>
              <a href="#">Alumni Association</a>
            </div>
          </Link>
        </Divs>
        <Divs>
          <Link to="/committees">
            <div className="border">
              <Divs1>
                <img src={Committee} alt="" />
              </Divs1>
              <a href="#">Committees</a>
            </div>
          </Link>
        </Divs>
        <Divs>
          <Link to="/mcc">
            <div className="border">
              <Divs1>
                <img src={mcc} alt="" />
              </Divs1>
              <a href="#">MCC</a>
            </div>
          </Link>
        </Divs>
        <Divs>
          <Link to="/activityClub">
            <div className="border">
              <Divs1>
                <img src={activitiesclub} alt="" />
              </Divs1>
              <a href="#">Activity Clubs</a>
            </div>
          </Link>
        </Divs>
      </Slider>
      {/* ) : ( */}
      {/* <DIVSECTION>
          <UL>
            <li>
              <Link to="/culac">
              
                  <div className="box">
                    <img src={culac} alt="" />
                    <a href="#">Cochin University <br/>Legal Aid Clinic </a>       
                  </div>
                 
              
              </Link>
            </li>
            <li>
              <Link to="/mcc">
               
                  <div className="box">

                    <img src={mcc} alt="" />
                    <a href="#">Moot Court Committee</a>
                  </div>
                  
               
              </Link>
            </li>
            <li>
              <Link to="/alumni">
    
                  <div className="box">
                    <img src={alumni} alt="alumni" />
                    <a href="#">Alumni Association </a>
                  </div>
                 
                
              </Link>
            </li>
            <li>
              <Link to="/committees">
               
                  <div className="box">
                    <img src={Committee} alt="" />
                 
                  <a href="#">Committees</a>
                </div>
              </Link>
            </li>
           
            <li>
              <Link to="/activityClub">
                  <div className="box">
                    <img src={activitiesclub} alt="club" />
                    <a href="#">Activity Clubs</a>
                  </div>
              </Link>
            </li>
          </UL>
        </DIVSECTION>
      )} */}
    </Main>
  );
}
const Main = Styled.div`
background: #FFF;
.slick-prev:before, .slick-next:before{
  font-size:30px;
  color:#BB0000 !important;
  
}
.slick-slider{
  padding:0 30px;
}
`;

const DIVSECTION = Styled.div`

    background: #FFF;
    height: 300px;
    width:100%;
    @media screen and (max-width:1400px){
      height: 360px;
    }
    @media screen and (max-width:1280px){
      height: 330px;
    }
    @media screen and (max-width:1080px){
        height:300px;
    }
    @media screen and (max-width:960px){
      height:260px;
  }
  
`;
const H1 = Styled.h1`
    font-family:Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #0A114E;
    display: flex;
    margin:0 auto; 
    margin-left: 60px;
    padding-top: 36px;
    @media screen and (max-width:1080px){
      font-size: 28px;
    }
    @media screen and (max-width:768px){
      font-size: 26px;
    }
    @media screen and (max-width:411px){
      font-size: 24px;
      margin-left: 20px;
    }
`;
const UL = Styled.ul`
    display: flex;
    flex-direction:row;
    justify-content: space-evenly;
    margin-top: 40px;

    li{
        list-style:none;
        text-align: center;
        a {
          width: 300px;
          font-family:Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          text-decoration:none;
          color: #0A114E;
          &:hover{
              color:#E01E38;
          }
    }
}
div.box{
        display:block;
        text-align:center;
        width: 210px;
        height: 202px;
        color: #FFFFFF;
        border-radius: 4px 4px 4px 4px ;
        margin-bottom: 20px;
        margin-left: -20px;
        @media(max-width:1164px){
          width: 177px;
          height: 177px;
        }
      }
       img {
            width: 100%;
            height: 100%; 
            border: 7px solid #0A114E; 
            : hover {
                -webkit-transform: scale(1.3);
	              transform: scale(1.1);
                transition: .3s ease-in-out;
                border: 7px solid #E01E38; 
              }
            }
      @media screen and (max-width:1400px){
        li{
          div.box{
            width:180px;
            height:168px;
          }
        }
      }
      @media screen and (max-width:1080px){
        li{
          div.box{
            width:154px;
            height:136px;
          }
        }
      }
      @media screen and (max-width:960px){
        li{
          div.box{
            width:140px;
            height:124px;
          }
        }
      }`;
const Divs = Styled.div`
a {
    width: 300px;
    font-family:PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    align-items: center;
    text-decoration:none;
    color: #0A114E;
    text-align: center;
    &:hover{
        color:#E01E38;
    } 
}`;

const Divs1 = Styled.div`display:flex;
justify-content:center;
margin-top: 31px;
img{
  height: 202px;
  width: 50%px;
  @media(max-width:375px){
    width: 60%px;
   }
   :hover  {
    -webkit-transform: scale(1.3);
    transform: scale(1.1);
    transition: .3s ease-in-out;
    border: 7px solid #0A114E; 

  }
}`;
