import React,{useEffect} from "react";
import Styled from "styled-components";
import vrk from "../Images/krishna_iyer_chair.svg";

export default function KrishnaIyerChair() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <div>
      <DIV>
        <BOX>
          <h1>Justice V. R. Krishna Iyer Chair on Human Rights</h1>

          <COLOR>
            <TOP>
              <p className="above">
                The chair was established in CUSAT vide U.O. No. PL (UGC) 3/376
                Krishna Iyer Chair/2010 dtd.09-09-2010 for a one time grant of
                Rs. 83 lakhs from the State Government in 2012 September. The
                Chair was established in honour of the Late Justice V. R.
                Krishna Iyer, the ardent champion of Human Rights who through
                his various landmark judgments has espoused the cause of Human
                Rights. The mission of the Chair is to propagate the human
                rights based jurisprudence developed by the great man Justice
                V. R. Krishna Iyer and to spread awareness about human rights in
                the society especially among students and teachers. The
                objective of the Chair is also to give the humane touch to every
                aspect of life.
              </p>

              <p className="below">
                The Chair has conducted various extension activities, workshops
                and seminars in the area of health care, patients’ rights, right
                to privacy, socio economic rights etc. The Chair has also taken
                active interest in handling classes on the concept of human
                rights in various colleges where Human Rights and Duties
                Education is introduced as a part of their curriculum, Chair has
                also conducted online Faculty Development Programmes, and
                training programmes to teachers. Some students did their
                internship under Justice V. R. Krishna Iyer Chair in February
                2020 for 21 days. They collected all the judgments of Justice
                V. R. Krishna Iyer and a team of Dr. Seema P. S., Dr. Aneesh V.
                Pillai, and Mr. Hari Govind P. C. guided them to classify those
                judgments into several headings. Thus the judgments are
                classified into various heads like Labour Rights, Rights of the
                Accused, Prisoners’ Rights, and International Human Rights Law
                and the incorporation into Indian Law. The Chair is now working
                on these areas to make it into a Book. The Chair has also
                published an edited book on Contours of Right to Privacy. The
                vision of the Chair is to identify the areas where human rights
                violation is rampant, and to take necessary steps for its
                prevention through various measures.
              </p>
              <H3>Coordinator:</H3> <H4>Dr. Seema P. S., Associate Professor, SLS</H4>
            </TOP>
            <a href="/downloads">Downloads</a>
          </COLOR>
        </BOX>
      </DIV>
    </div>
  );
}
const H3 = Styled.h3`
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
    @media (max-width:395px){
      font-size: 14px;
    }
   `;
   const H4 = Styled.h4`
   font-family: PT Serif;
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   line-height: 16px;
   color: #000000;
   @media (max-width:640px){
    font-size: 18px;
  }
  @media (max-width:540px){
    font-size: 16px;
  }
  @media (max-width:395px){
    font-size: 14px;
  }`
const DIV = Styled.div`
    background: #FAFAFA;
    display:flex ;
    justify-content:center;
    align-items: center;
    width:100%;`;

const BOX = Styled.div`
   
    width:85%;
    margin:50px auto;
    
    h1{
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        font-weight:500;
        color: #E01E38;
        display:flex;
    }
    @media (max-width:660px){
        h1{
            font-size: 30px;
            }
    }
    @media (max-width:534px){
      margin:30px auto;
        h1{
         font-size: 28px;
         }
       }
       @media (max-width:395px){
        padding:16px;
        h1{
         font-size: 26px;
         }
`;
const COLOR = Styled.div`

    background: white;
    padding: 20px  40px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    @media (max-width:368px){
        padding: 10px;
    }
`;

const TOP = Styled.div`
     p{
        font-family: PT Serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-align:justify;
        @media (max-width:640px){
            font-size: 18px;
          }
          @media (max-width:540px){
            font-size: 16px;
          }
        }
   
`;
