import React, { Component } from 'react';
import Styled from "styled-components";
import Pdf from "../Images/pdf.png";

class Archive extends Component {
    render() {
        return (
            <Box>
                <Container>
                    <h1>Archive</h1>
                    <Flex>
                        <Card>
                            <img src={Pdf} alt="pdf" />
                            <p>bba 2021.pdf</p>
                        </Card>
                    </Flex>
                </Container>
            </Box>
        );
    }
}

const Box = Styled.div`
    max-width:1280px;
    margin :50px auto;
    h1{
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        margin:0px;
        padding-bottom:0px;
        font-size: 32px;
        color: #E01E38;
        margin-bottom:50px;
        margin-top:36px;
    }
`;
const Card = Styled.div`
    display:flex;
    flex-direction:column;
    width:250px;
    img{
        width:100px;
        height:125px;
        margin:0 auto;
    }
    p{
        width:80%;
        margin:10px auto;
        text-align:center;
    }
`;
const Flex = Styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
`;
const Container = Styled.div`
    width:80%;
    margin:0 auto;
`;

export default Archive;