import React from "react";
import Styled from "styled-components";
import Logo from "../Images/cusat-logo.svg";
import Logor from "../Images/Logor.png";
import facebook from "../Images/facebook.svg";
import instagram from "../Images/instagram.svg";
import sbi from "../Images/sbi.svg";
import contact from "../Images/Contact.svg";
import email from "../Images/email.svg";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Ficon from "../Images/facebook1.svg";
import Iicon from "../Images/instagram1.svg";
import Yicon from "../Images/youtubered.svg";
import Licon from "../Images/linkdInred.svg";
import Maild from "../Images/maild.svg";
import Calld from "../Images/calld.svg";
import YouTubeIcon from "../Images/youtube.svg";
import LinkedInIcon from "../Images/linkdIn.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./Style.css";
import DehazeIcon from "@material-ui/icons/Dehaze";
const useStyles = makeStyles({
  list: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
});
//dreawer
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function () {
  //drawer

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log(event.currentTarget, "one targer");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);

  const handleClick2 = (event) => {
    console.log(event.currentTarget, "two targer");
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorE3(null);
  };
  const [anchorE4, setAnchorE4] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorE4(null);
  };
  const [anchorE6, setAnchorE6] = React.useState(null);

  const handleClose6 = () => {
    setAnchorE6(null);
  };
  const handleClick6 = (event) => {
    setAnchorE6(event.currentTarget);
  };

  //..........
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    handleClose();
    handleClose2();
    handleClose3();
    handleClose4();
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <DIVH0>
          <DIVH>
            <h1>
              <Link to="/">
                <img src={Logor} alt="logo" />
              </Link>
            </h1>
            <ul>
              <li className="name">School of Legal Studies</li>
              <li className="name1">
                COCHIN UNIVERSITY OF SCIENCE AND TECHNOLOGY
              </li>
            </ul>
          </DIVH>
          <Closd
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <span>
              {" "}
              <CloseIcon fontSize="large" />
            </span>
          </Closd>
          <Main>
            <ul>
              <Linked
                to="/"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                {" "}
                <li>Home</li>
              </Linked>
              <Linked
                to="/aboutUs"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                {" "}
                <li>About us</li>
              </Linked>

              <li onClick={handleClick}>
                COURSES <ArrowDropDownIcon />
              </li>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <DROPD>
                  <ul>
                    <Linked
                      to="/PHDProgram"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Ph. D</li>
                    </Linked>
                    <Linked
                      to="/Masters"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>LLM</li>
                    </Linked>
                    <Linked
                      to="/BBA"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>
                        {" "}
                        <a>BBA. LLB (Hons.)</a>
                      </li>
                    </Linked>
                    <Linked
                      to="/BCom"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>B. Com. LLB (Hons.)</li>{" "}
                    </Linked>

                    <Linked
                      to="/ThreeyearLLB"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Three Year LLB</li>
                    </Linked>
                  </ul>
                </DROPD>
              </StyledMenu>

              <li onClick={handleClick3}>
                RESEARCH <ArrowDropDownIcon />
              </li>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorE3}
                keepMounted
                open={Boolean(anchorE3)}
                onClose={handleClose3}
              >
                <DROPD>
                  <ul>
                    <li>Research Centres </li>
                    <Linked
                      to="/sap"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>UGC SAP</li>
                    </Linked>
                    <Linked
                      to="/krishnaIyer"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Chair on Human Rights</li>
                    </Linked>
                    <Linked
                      to="/nrmcentre"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Interdisciplinary Centre</li>
                    </Linked>

                    <li>Research Activities </li>
                    <Linked
                      to="/projects"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li> Research Projects </li>
                    </Linked>
                    {/* <Linked
                      to="/projects"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li> Research Areas </li>
                    </Linked> */}
                    <Linked
                      to="/mous"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li> MOUs </li>
                    </Linked>
                  </ul>
                </DROPD>
              </StyledMenu>
              <li onClick={handleClick6}>
                EVENTS <ArrowDropDownIcon />
              </li>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorE6}
                keepMounted
                open={Boolean(anchorE6)}
                onClose={handleClose6}
              >
                <DROPD>
                  <ul>
                    <Linked
                      style={{ color: "black" }}
                      to="/seminars"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Seminars and Conferences</li>
                    </Linked>
                    <Linked
                      style={{ color: "black" }}
                      to="/wednesdaySeminars"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Wednesday Seminars</li>
                    </Linked>{" "}
                    <Linked
                      style={{ color: "black" }}
                      to="/publicLawLectures"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li> Public Law Lectures</li>
                    </Linked>{" "}
                    <Linked
                      style={{ color: "black" }}
                      to="/otherEvents"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Other Events</li>
                    </Linked>
                  </ul>
                </DROPD>
              </StyledMenu>

              <li onClick={handleClick4}>
                LIBRARY <ArrowDropDownIcon />
              </li>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorE4}
                keepMounted
                open={Boolean(anchorE4)}
                onClose={handleClose4}
              >
                <DROPD>
                  <ul>
                    <Linked
                      style={{ color: "black" }}
                      to="/slslibrary"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>SLS Library</li>
                    </Linked>
                    <li style={{ marginTop: "20px" }}>
                      {" "}
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="http://library.cusat.ac.in/"
                        target="_blank"
                      >
                        University Library{" "}
                      </a>
                    </li>
                  </ul>
                </DROPD>
              </StyledMenu>
              <li onClick={handleClick2}>
                STAFF <ArrowDropDownIcon />
              </li>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorE2}
                keepMounted
                open={Boolean(anchorE2)}
                onClose={handleClose2}
              >
                <DROPD>
                  <ul>
                    <Linked
                      to="/faculty"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Teaching</li>
                    </Linked>
                    <Linked
                      to="/staffList"
                      onClick={toggleDrawer(anchor, false)}
                      onKeyDown={toggleDrawer(anchor, false)}
                    >
                      <li>Non-Teaching</li>
                    </Linked>
                  </ul>
                </DROPD>
              </StyledMenu>

              <Linked
                to="/ContactUs"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
              >
                {" "}
                <li>CONTACT US</li>
              </Linked>

              <FBIS>
                <a
                  href="https://www.facebook.com/School-of-Legal-Studies-110214751204757"
                  target="blank"
                >
                  <img src={Ficon} />
                </a>
                <a href="https://www.instagram.com/slscusat/" target="blank">
                  <img src={Iicon} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCZdGf9vJY1oNDAV066ybcKQ"
                  target="blank"
                >
                  <img src={Yicon} />
                </a>
                <a
                  href="https://www.linkedin.com/company/79323977/admin/"
                  target="blank"
                >
                  <img src={Licon} />
                </a>
                <a
                  href="https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=838692"
                  target="blank"
                >
                  <img src={sbi} className="sbi" />
                </a>
              </FBIS>
              <MOBMAIl>
                <a href="#">
                  <img src={Calld} />
                  +91 0484 257 5465{" "}
                </a>
                <a href="mailto:slcusat@gmail.com">
                  <img src={Maild} />
                  slcusat@gmail.com
                </a>
              </MOBMAIl>
            </ul>
          </Main>
        </DIVH0>
      </List>
    </div>
  );
  return (
    <div>
      <div id="header_sls">
        <Wrapper>
          <HeaderTop>
            <Left>
              <li>
                <a
                  href="https://www.facebook.com/School-of-Legal-Studies-110214751204757"
                  target="blank"
                >
                  <img src={facebook} alt="facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/slscusat/" target="blank">
                  <img src={instagram} alt="instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCZdGf9vJY1oNDAV066ybcKQ"
                  target="blank"
                >
                  <img src={YouTubeIcon} alt="Youtube" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/79323977/admin/"
                  target="blank"
                >
                  <img src={LinkedInIcon} alt="Linkedin" />
                </a>
              </li>
              <li className="large">
                <a
                  href="https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=838692"
                  target="blank"
                >
                  <img src={sbi} />
                </a>
              </li>
            </Left>
            <Right>
              <ul>
                <li>
                  <img src={contact} />
                </li>
                <li>
                  <a>+91 0484 257 5465 </a>
                  <a>Mobile +91 9383445550</a>
                </li>
              </ul>

              <ul>
                <li>
                  <img src={email} />
                </li>
                <li>
                  <a href="mailto:slcusat@gmail.com">slcusat@gmail.com </a>
                  <a href="mailto:sls@cusat.ac.in"> sls@cusat.ac.in</a>
                </li>
              </ul>
            </Right>
          </HeaderTop>
          <Header>
            <HeaderLeft>
              <h1>
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </h1>
              <ul>
                <li>School of Legal Studies</li>
                <li>COCHIN UNIVERSITY OF SCIENCE AND TECHNOLOGY</li>
              </ul>
            </HeaderLeft>
            <HeaderRight>
              <ul>
                <li>|| तेजस्विनावधीतमस्तु ||</li>
                <li>May learning illuminate us both </li>
              </ul>
            </HeaderRight>
            <HAMBERDIV>
              <React.Fragment key={"anchor"}>
                <Button onClick={toggleDrawer("anchor", true)}>
                  <span>
                    <DehazeIcon fontSize="large" />
                  </span>
                </Button>
                <Drawer
                  anchor={"anchor"}
                  open={state["anchor"]}
                  onClose={toggleDrawer("anchor", false)}
                >
                  {list("anchor")}
                </Drawer>
              </React.Fragment>
            </HAMBERDIV>
          </Header>
          <HeaderBottom>
            <ul>
              <Linked to="/">
                <li className="link">HOME</li>
              </Linked>
              <Linked to="/aboutUs">
                <li className="link">ABOUT US</li>
              </Linked>
              <li className="dropdown">
                COURSES
                <div class="dropdown-content">
                  <Link to="/PHDProgram">Ph. D</Link>
                  <Link to="/Masters">LLM</Link>
                  <Link to="/BBA" style={{ textDecoration: "none" }}>
                    BBA. LLB (Hons.)
                  </Link>
                  <Link to="/BCom">B. Com. LLB (Hons.)</Link>
                  <Link to="/ThreeyearLLB">Three Year LLB</Link>
                </div>
              </li>
              <li className="dropdown">
                STAFF
                {/* <div class="dropdown-content">
                  <Link to="/faculty">Faculty</Link>
                  <a className="padding">Staff</a>
                  <div className="dropdown-contentresearch">
                      <Link to="/sap">
                        <span>Office</span>
                      </Link>
                      <Link to="/krishnaIyer">
                        <span>Library</span>
                      </Link>
                    </div>
                </div> */}
                <div class="dropdown-content">
                  {/* <Link to="/faculty">Faculty</Link>  */}
                  <div className="dropdownresearch">
                    <a className="padding">Teaching</a>
                    <div className="dropdown-contentresearch">
                      <Link
                        to={{
                          pathname: "/faculty",
                          state: {
                            guest: false,
                          },
                        }}
                      >
                        <span> Faculty</span>
                      </Link>
                      <Link
                        to={{
                          pathname: "/faculty",
                          state: {
                            guest: true,
                          },
                        }}
                      >
                        <span>Guest Faculty</span>
                      </Link>
                    </div>
                  </div>
                  <div className="dropdownresearch">
                    <a className="padding">Non-Teaching</a>
                    <div className="dropdown-contentresearch">
                      <Link to="/staffList">
                        <span>Office</span>
                      </Link>
                      <Link to="/librarystaff">
                        <span>Library</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="dropdown">
                RESEARCH
                <div class="dropdown-content">
                  <div className="dropdownresearch">
                    <a className="padding">Research Centres</a>
                    <div className="dropdown-contentresearch">
                      <Link to="/sap">
                        <span>UGC SAP</span>
                      </Link>
                      <Link to="/krishnaIyer">
                        <span>Chair on Human Rights</span>
                      </Link>
                      <Link to="/nrmcentre">
                        <span>Interdisciplinary Centre</span>
                      </Link>
                    </div>
                  </div>
                  <div className="dropdownresearch">
                    <a className="padding">Research Activities</a>
                    <div className="dropdown-contentresearch">
                      <Link to="/projects">
                        <span>Research Projects</span>
                      </Link>
                      {/* <Link to="/projects">
                        <span>Research Areas</span>
                      </Link> */}
                      <Link to="/mous">
                        <span>MOUs</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="dropdown">
                EVENTS
                <div class="dropdown-content">
                  <Link to="/seminars" style={{ textDecoration: "none" }}>
                    Seminars and Conferences
                  </Link>
                  <Link
                    to="/wednesdaySeminars"
                    style={{ textDecoration: "none" }}
                  >
                    Wednesday Seminars
                  </Link>
                  <Link
                    to="/publicLawLectures"
                    style={{ textDecoration: "none" }}
                  >
                    Public Law Lectures
                  </Link>
                  <Link to="/otherEvents" style={{ textDecoration: "none" }}>
                    Other Events
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                LIBRARY
                <div class="dropdown-content">
                  <Link to="/slslibrary" style={{ textDecoration: "none" }}>
                    SLS Library
                  </Link>
                  <a href="http://library.cusat.ac.in/" target="blank">
                    University Library
                  </a>
                </div>
              </li>
              <Linked to="/downloads">
                <li>DOWNLOADS</li>
              </Linked>
              <Linked to="/contactUs">
                <li>CONTACT US</li>
              </Linked>
            </ul>
          </HeaderBottom>
        </Wrapper>
      </div>
    </div>
  );
}
const Wrapper = Styled.div`
`;

const Header = Styled.header`
height:105px;
display:flex;
justify-content: space-between;
align-items:center;
width:100%;
background: linear-gradient(307.29deg, rgba(220, 42, 42, 0.072) -34.12%, rgba(182, 0, 0, 0.9) 59.23%), #E90101;
span{
  color:white;
  display:none;
  
  @media(max-width:768px){
    display:block;
    margin-left:-35px;
  }
  @media(max-width:500px){
    display:block;
    margin-left:-20px;
  }
  @media(max-width:440px){
    display:block;
    margin-left:-5px;
  }
}`;

const HeaderLeft = Styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
h1{
    a{
      text-decoration:none;
        img{
            width:56.23px;
            height:69.52px;
            margin-left:94px;
            @media(max-width:1080px){
              margin-left:68px;
            }
            @media(max-width:960px){
              display:none;
            }
            @media(max-width:600px){
              margin-left:50px;
            }
            @media(max-width:540px){
              margin-left:50px;
            }
            @media(max-width:464px){
              margin-left:20px;
            }
            @media(max-width:432px){
              margin-left:8px;
            }
        }
    }
}
ul{
    margin-left: 20px;
    li{
        list-style:none;
        color:#fff;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        font-style: normal;
        text-align:left;
        text-decoration:none;
        @media(max-width:1080px){
          font-size: 18px;
        }

        &:first-child{
            font-size: 28px;
            line-height: 32px;
            padding-bottom:4px;
            font-family: PT Serif;
            
            @media(max-width:1220px){
              font-size: 26px;
            }
            @media(max-width:1080px){
              font-size: 24px;
            }
            @media(max-width:540px){
              font-size: 24px;
            }
            @media(max-width:440px){
              font-size: 22px;
            }
            @media(max-width:336px){
              font-size: 20px;
            }
            @media(max-width:324px){
              font-size: 18px;
            }
            
            
        }
        &:last-child{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            text-align: center;
            font-family: PT Serif;
            color: rgba(255, 255, 255, 0.87);
            @media(max-width:540px){
              font-size: 12px;
            }
            @media(max-width:420px){
              font-size: 11px;
            }
            @media(max-width:336px){
              font-size: 10px;
            
          
        }
    }
    
}
`;

const HeaderRight = Styled.div`
display:flex;

@media(max-width:768px){
  display:none;
}
ul{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-right:68px;
  li{
    list-style:none;
    color:#fff;
    &:first-child{
      margin-bottom:8px;
    }
    &:last-child{
      font-family: Yatra One;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #FFFFFF;
    }
  }
}
@media(max-width:1080px){
  ul{
    margin-right:30px;
    li{
      &:last-child{
        font-size: 14px;
      }
    }
  }
}
`;
const HeaderTop = Styled.div`

@media (max-width: 768px) {
  display:none;
}
width:100%;
height:46px;
background: #8B0000;
display: flex;
justify-content:space-between;
align-items:center;`;

const HeaderBottom = Styled.div`
background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #180E04;
width:100%;

ul{
    margin: 0 auto;
    display:flex;
    align-items:center;
    justify-content: space-evenly;
    width:100%;
    
    
    li{
        list-style:none;
        color:white;
        display:flex;
        align-items:center;
        font-family: PT Serif;
        font-weight:500;
        height: 46px;
        text-decoration:none;
        a{
            color:#fff;
            font-family: PT Serif;
            font-style: normal;
            font-size: 14px;
            line-height: 17px;
            text-decoration:none;
           
        }
      
      } 
  }
  @media (max-width: 768px) {
    display:none;
  }
  @media (max-width:960px){
   ul{
    li{
      font-weight:500;
    }

     }
   }
   @media (max-width:960px){
    ul{
     li{
       font-size:13px;
       font-weight:600;
     }
 
      }
    }
  }
`;
const Left = Styled.ul`
display:flex;
justify-content:space-between;
align-items:center;
list-style:none;
margin-left:94px;
gap: 20px;

li{
  &:last-child{
    img{
      width:75px;
    }
  }
  a{
    text-decoration:none;
    img{
       width:20px;
       cursor:pointer;
       
         
       }
    }
}
@media (max-width: 1080px){
  margin-left:64px;
  li{
    &:last-child{
      margin-left:18px;
    }
  }
}
`;
const Right = Styled.div`
display:flex;
margin-right:50px;
gap:30px;
ul{
  display:flex;
flex-direction:row;
text-align:center;
list-style:none;
}
li{
  &:first-child img{
   
  }
  a{
    font-family: PT Serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #FFFFFF;
    margin-right:14px;
    text-decoration: none;
    }
  img{
    margin-right:8px;
    
  }
}
@media screen and (max-width:1150px){
    gap:24px
}
@media screen and (max-width:1080px){
  gap:16px;
  margin-right:30px;
  ul{
    li{
      a{
        font-size:12px;

      }
    }
  }
}`;
const DIVH0 = Styled.div`
position:relative;
`;
const DIVH = Styled.div`display:flex;
  width:76%;

justify-content: space-around;
    height: 130px;
    align-items: center;
  @media(max-width:668px){
    width:82%;
  }
  @media(max-width:548px){
    width:96%;
  }
  @media(max-width:536px){
    width:85%;
  }
  @media(max-width:444px){
    width:85%;
  }
  @media(max-width:406px){
    width:55%;
  }
  // @media(max-width:350px){
  //   width:50%;
  // }
  img{
   @media(max-width:411px){
    margin-left: 33px;
   } 
  }
    ul{
      @media(max-width:411px){
        display:none;
      }
      display:flex;
      flex-direction:column;
      gap:10px;
      list-style:none;
      li.name{
        font-family: PT Serif;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 24px;
text-transform: uppercase;

color: #BD202F;
@media(max-width:540px){
  font-size: 22px;
}
@media(max-width:350px){
  font-size: 20px;
}
     }
      li.name1{
        font-family: PT Serif;
      font-style: normal;
      font-weight: bold;
      font-size: 7px;
      line-height: 9px;
      text-transform: uppercase;
      font-size: 14px;
      color: #BD202F;
      @media(max-width:540px){
        font-size: 10px;
      }
      @media(max-width:350px){
        font-size: 9px;
      }
      }
    }
`;
const Closd = Styled.div`position:absolute;
    left: 87%;
    top: 5%;
    @media(max-width:411px){
      left: 86%;
    top: 5%;
    }
    span{
      color: #BD202F;
;
    }
`;
const Main = Styled.div` display:flex;

justify-content: center;
ul{
  list-style:none;
  text-align: center;
 
  li{
   
    margin-top:20px;
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    width: 150px;

display: flex;
justify-content: center;
 
color: #BD202F;
@media(max-width:768px){
  display:flex;
  justify-content:start;
  align-items: center;
}
  }
  
}`;

const DROPD = Styled.div`ul{
  width:200px;
  display:flex;
 
  flex-direction:column;
  justify-content:center;
  text-align:center;
  li{
    cursor:pointer;
    font-family: PT Serif;
font-style: normal;
font-weight: 600;
font-size: 14px;
height:30px;
margin-bottom:10px;
color: black;
line-height: 17px;
display: flex;
align-items: center;
text-align:center;
display:block;
border-bottom:1px solid #f5f5f5;
text-decoration:none;
  
}`;
const FBIS = Styled.div`margin-top:50px;
display: flex;
justify-content:center;
align-items:center;
    gap: 50px;
    .sbi{
      width:40px;
      height:40px;
    }
`;
const MOBMAIl = Styled.div`margin-top:30px;
width:100%;
display: flex;
gap:10px;
   flex-direction:column;
    a{ 
      text-decoration:none;
      color:black;
      img{
        margin-right:10px;
      }
     
    }
`;
const HAMBERDIV = Styled.div`
display:none;
@media(max-width:768px){
  display:block;
}`;
const Linked = Styled(Link)`text-decoration:none;`;
