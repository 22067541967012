import React ,{useEffect} from "react";
import Styled from "styled-components";
export default function SlsLibrary() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <DIV>
      <BOX>
        <H1>School of Legal Studies Library</H1>
        <COLOR>
          <BOTTOM className="bottom">
            <p>
              Being an intensive research centre, the activities of which are
              mostly doctrinal, library is the heart and soul of the School of
              Legal Studies, as any other educational institutions. As an
              integral part of the School, SLS library was established in 1971
              for meeting the legal research need of students and teachers. It
              has an open access system so as to make it easier for the readers
              to access the library resources. Students from other Universities,
              researchers from different parts of country and advocates are
              using this library for reference. It’s a fully Wi-Fi enabled
              library. It is dedicated from 9 a.m. to 8 p.m. everyday excepting
              the holidays for the School of Legal Studies.
            </p>
            <p>
              The Library has a collection of more than 35000 documents,
              including collection of very rare materials. The collection is
              inclusive of several valuable reference books, back volumes of
              journals, law reports, and statutes. Ph. D theses and LL M
              dissertations submitted to the Cochin University are also
              available in the Library. Library provides access to several
              foreign and Indian journals such as Yale Law Journal; Cambridge
              Law Journal; Harvard Law Review; Columbia Law Review; Michigan Law
              Review; Canadian Bar Review; Law and Contemporary Problems; AIR;
              SCC; Consumer Protection Journals; Corporate Cases; Criminal Law
              Journals; Journals of IPR and Journal of Indian Law Institute.
            </p>
            <p>
              Library also has subscription to Electronic databases like JSTOR;
              Manupatra; and SCC online.
            </p>
            <p>
              An open source integrated library management software KOHA is used
              for Library automation.
            </p>
            <p>
              {" "}
              Department library’s OPAC is available at{" "}
              <a href="http://unionopac.cusat.ac.in/cgi-bin/koha/opac-search.pl?q=sls" target="blank">unionopac.cusat.ac.in</a>.
            </p>
          </BOTTOM>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const DIV = Styled.div`
background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;

`;
const COLOR = Styled.div`
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    background: white;
    padding: 20px 30px 20px 30px;
    margin-bottom:50px;
`;

const BOX = Styled.div`

width:85%;

margin:0 auto;

`;
const H1 = Styled.h1`
font-family: Oswald;
font-style: normal;
font-weight: normal;
font-size: 34px;
line-height:20px;
margin-top:50px;
margin-bottom:28px;
font-weight:500;
color: #E01E38;
padding-top: 20px;
display:flex;
@media (max-width:860px){
  font-size: 34px;
}
@media (max-width:660px){
  font-size: 32px;
}
@media (max-width:540px){
  font-size: 30px;
}
@media (max-width:460px){
  font-size: 28px;
}
@media (max-width:368px){
  font-size: 26px;
}`;

const BOTTOM = Styled.div`

  p{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom:20px;
    text-align:justify;
    @media (max-width:660px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
    @media (max-width:460px){
      font-size: 16px;
    }
    @media (max-width:368px){
      font-size: 16px;
    }
  }
 
  `;
