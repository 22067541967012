import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import Pdf from "../Images/pdf.png";
import axios from "axios";
import { Link } from "react-router-dom";

function Masters() {
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    window.scroll(0, 0);
    axios
      .get(`${API}/course/llm/view/`)
      .then((res) => {
        console.log(res.data.data);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Container>
        <Box>
          <h1>Master of Laws (LL.M) </h1>
          <Bottom>
            <div className="innerbox">
              <p className="masters">
                The program is designed to churn from the student’s mainly five
                qualities, a researcher, a teacher, an orator, an articulator
                and a writer. This program is intended to give the students a
                deep understanding in any particular branch of Law with five
                core papers spread to two semesters. This program also aims to
                give the students a deep insight into judicial process and the
                interpretation of provisions of Law as well as judgments. The
                students are also given an opportunity to articulate their views
                on contemporary developments of Law through case analysis every
                week for four hours. The ability of the students to handle
                classes is also assessed by evaluating the presentation of
                assignments. The program is also intended to ignite the research
                ability of the students on the one hand through a paper on
                Research Methodology, and through a Dissertation on the other.{" "}
              </p>
              <Div>
                <p className="number">Numbers of Seats</p>
                <p className="colon">:</p>
                <p className="medium">30 + Supernumerary</p>
              </Div>
              <Div>
                <p className="number">Duration</p>
                <p className="colon">:</p>
                <p className="medium">
                  One year/Two years (Two Semesters/Four Semesters)
                </p>
              </Div>
              <h6>Specializations Available</h6>
              <p className="special">
                SLS offers One Year full-time LL. M. Degree with specializations
                in:{" "}
              </p>
              <UL>
                <li>Administrative Law</li>
                <li>Commercial Law</li>
                <li>Constitutional Law</li>
                <li>Consumer and Competition Laws</li>
                <li>Corporate Governance</li>
                <li>Criminal Law</li>
                <li>Environmental Law</li>
                <li>Human Rights Law</li>
                <li>Intellectual Property Law</li>
                <li>International Criminal Law</li>
                <li> International Dispute Resolution and Arbitration</li>

                <li>International Trade and Investment Law</li>
                <li>Labour Laws</li>
                <li>Maritime Law</li>
              </UL>
              <Note>
                <p className="number1">Note</p>
                <p className="colon1">:</p>
                <p className="medium1">
                  Among the above list selected subjects will be offered in each
                  year as specialisations.{" "}
                </p>
              </Note>
              <h3>Minimum Qualifications Required for Applying:</h3>
              <p className="end">
                Pass Bachelor's Degree in Law with 55% markis from any
                University of Kerala or an examination of anyother University
                accepted by the Syndicate as equivalent thereto.
              </p>
              <p className="end">
                Scheduled Caste/Scheduled Tribe candidates are eligible for
                consession admisible under the orders of the Government.
              </p>
              <p className="end">
                Reservation of seats to foreign candidates will be as per the
                decision of the University.Provided that in the case of foreign
                students,especially from SAARC and other developing
                countries,the minimum requirement for percentage of marks for
                the qualifying degree examination can be 50%/second class as per
                their eligible degree requirement instead of 55%.
              </p>
              <Div>
                <p className="number">Admission Procedure</p>
                <p className="colon">:</p>
                <p className="medium">
                  Admission through Common Entrance Test (CUSAT - CAT) **
                </p>
              </Div>
              <p class="disclaimer">
                ** Subject to changes made to these regulations from time to
                time by the University.
              </p>
              {response?.map((res) => (
              <>
                <a href={res.file} target="_blank">
                  <img src={Pdf} alt="pdf" />
                  <h3>{res.file_name}</h3>Read more
                </a>

                <Link to="/phdarchive">
                  <p className="end">Click here to view archived files</p>
                </Link>
              </>
            ))}
            </div>
          </Bottom>
        </Box>
      </Container>
    </div>
  );
}

export default Masters;

const Container = Styled.div`
display:flex;
align-items:center;
justify-content:center;
background: #FAFAFA;
margin:50px auto;

@media (max-width:860px){
  
}
@media (max-width:600px){
 
  margin:30px auto;
}
@media (max-width:412px){
  
}
`;

const Box = Styled.div`
width:85%;
p.masters{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
}
h6{
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin:0px;
    padding:16px 0px;
}
h1{
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    color: #E01E38;
    font-weight:500;
  }
  p.medium{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    width: 70%;
    text-align:justify;
  }
   p.number{
       
        font-family: PT Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        width: 20%;
   }
   p.colon{
    width:8%;
  }
  h3{
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  p.special{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
  }
  p.end{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
  }
  @media (max-width:860px){
    p.masters{
      font-size: 18px;
    }
    h6{
      font-size: 18px;
    }
    h1{
      font-size: 30px;
    
    }
    p.end{
      font-size: 18px;
    }
    p.special{
      font-size: 18px;
    }
    h3{
      font-size: 18px;
    }
    p.number{
      font-size: 18px;
    }
    p.medium{
      font-size: 18px;
    }
  }
  @media (max-width:700px){
    p.medium{
      width: 65%;
    }
    p.number{
      width: 25%;
    }
  }
  @media (max-width:600px){
    p.medium{
      font-size: 16px;
    }
    p.number{
      font-size: 16px;
    }
    p.masters{
      font-size: 16px;
    }
    h6{
      font-size: 16px;
    }
    h1{
      font-size: 28px;
     
    }
    p.end{
      font-size: 16px;
    }
    p.special{
      font-size: 16px;
    }
    h3{
      font-size: 16px;
    }
  }
  @media (max-width:500px){
    p.medium{
      width:50%;
    }
    p.number{
      width:30%;
    }
    h1{
      font-size: 26px;
     
    }
  }`;
const Div = Styled.div`
display:flex;
align-items:center;`;

const Bottom = Styled.div`
background:white;
img{
  width:70px;
  height:75px;
}
div.innerbox{
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
padding:30px;
}
p.disclaimer{
  text-align:justify;
  font-style:italic;
  font-size:20px;
  line-height:24px;
}
@media (max-width:860px){
  div.innerbox{
    padding:20px 30px 40px 30px; 
    }
    p.disclaimer{
      font-size: 18px;
    }
}
@media (max-width:600px){
  div.innerbox{
 
  }
  p.disclaimer{
    font-size: 16px;
  }
}
@media (max-width:412px){
  div.innerbox{
    
  };
}
`;
const UL = Styled.ul`
padding-left:10%;
font-family: PT Serif;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
color: #000000;
@media (max-width:860px){
  font-size: 18px;
}
@media (max-width:600px){
  font-size: 16px;
}`;

const Note = Styled.div`
display:flex;
align-items:center;

p.medium1{
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;

  text-align:justify;
}
p.colon1{
  width: 2%;
}
p.number1{
  font-family: PT Serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
       width:4%;
}
@media (max-width:1500px){
  p.number1{
    width:8%;
  }
  p.colon1{
    width: 4%;
  }
}
@media (max-width:1060px){
  p.number1{
    width:12%;
  }
  p.colon1{
    width: 6%;
  }
  p.medium1{
    width:120%;
  }
}
@media (max-width:860px){
  p.number1{
    width:12%;
    font-size: 18px;
  }
  p.medium1{
    width:80%;
    font-size: 18px;
  }
}
@media (max-width:600px){
  p.number1{
    
    font-size: 16px;
  }
  p.medium1{
   
    font-size: 16px;
  }
}
`;
