import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Styled from "styled-components";
import axios from "axios";
import "./Style.css" ;
import Pdf from "../Images/pdf.png";
import { Link } from "react-router-dom";

const useStyless = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
    
    },
  })
);

export default function SimpleAccordion() {
  const classes = useStyless();
  const [response, setresponse] = useState([]);
  const API = process.env.REACT_APP_API;
  useEffect(() => {
    window.scroll(0,0);
    axios
      .get(`${API}/news/view/`)
      .then((res) => {
        console.log(res.data.data,);
        setresponse(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DIV>
      <div className="flex">
        <h1>NEWS & EVENTS</h1><Link to="/newsarchive"><h1>Archive</h1></Link>
      </div>
      <Box>
      <div className="cover">
        {response.map((res) => (
          <Accordion  id="News">
            <AccordionSummary
              expandIcon={<Button>READ MORE</Button>}
              aria-controls="read-more"
              id="read-more"
            >
              <Typography className={classes.heading}>
              <Div>
              <span>{res.date}</span>
              <h3>{res.title}</h3>
              
            </Div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Bottom>
                  <div>
                    <p>{res.discription}</p>
                    <a href={res.file} target ="_blank"><img src={Pdf} alt="pdf" /></a>
                  </div>
                  
                </Bottom>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      </Box>
    </DIV>
  );
}
const DIV = Styled.div`
  width:85%;
  max-width:896px;
  margin :50px auto;
  div.flex{
    display:flex;
    justify-content:space-between;
    a{
      text-decoration:none;
    }
  }
  h1{
    
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    margin:0px;
    padding-bottom:0px;
    font-size: 32px;
    color: #E01E38;
  }
  div.cover{
    margin-top:30px;
  }
 @media (max-width:860px){
  h1{
  font-size:30px;
  }
 }
 @media (max-width:768px){
  h1{
    font-size:28px;
  }
 }
 @media (max-width:600px){
  h1{
    font-size: 26px;
  }
 }
 @media (max-width:480px){
  h1{
    font-size: 25px;
  }
  @media (max-width:411px){
    h1{
      font-size: 23px;
    }
 }
`;

const Box = Styled.div`
`
const Button = Styled.div`
width: 102px;
height: 24px;
background: #BA090B;
font-family: PT Serif;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
padding-top:10px;
@media (max-width:768px){
  width: 90px;
height: 20px;
font-size: 10px;
padding-top:8px;
}
@media (max-width:540px){
  width: 80px;
  height: 15px;
  font-size: 8px;
  padding-top:6px;
}
`
;
const Bottom = Styled.div`
  display:block;
  img{
    width:70px;
    height:75px;
  }
  div{
    width:85%;
    margin :10px auto;
    p{
      font-family: PT Serif;
      width:100%;
      
      text-align: justify;
      font-size:20px;
    }
  }
  @media (max-width:768px){
    div{
      p{
        font-size:18px;
      }
    }
  }
  @media (max-width:540px){
    div{
      p{
        font-size:16px;
      }
    }
  }
 
`;
const Div = Styled.div`
width:85%;
max-width:896px;
margin :30px auto;
span{
  font-size:18px;
  font-family: PT Serif;
}
h3{
  font-size:20px;
  font-family: PT Serif;
}
p{
  font-size:20px;
  font-family: PT Serif;
}
@media (max-width:768px){
  span{
    font-size:16px;
  }
  h3{
    font-size:18px;
  }
  p{
    font-size:18px;
  }
}
@media (max-width:540px){
  span{
    font-size:14px;
  }
  h3{
    font-size:16px;
  }
  p{
    font-size:16px;
  }
}
@media (max-width:411px){
  span{
    font-size:13px;
  }
  h3{
    font-size:14px;
  }
  p{
    font-size:15px;
  }
}

`
