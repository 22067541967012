import React from "react";
import Styled from "styled-components";

export default function QuickLinks() {
  return (
    <Links>
      <ul>
        <li>Quick Links</li>
        <li>
          <a href="https://www.jstor.org/" target="blank">JSTOR</a>
        </li>
        <li>
          <a href="https://www.manupatrafast.com/" target="blank">Manupatra</a>
        </li>
        <li>
          <a href="http://dspace.cusat.ac.in/jspui/" target="blank">D-Space</a>
        </li>
        <li>
          <a href="https://epathshala.nic.in/" target="blank">E-Pathsala</a>
        </li>
        <li>
          <a href="http://nss.cusat.ac.in/mooc/swayam.php" target="blank">Swayam</a>
        </li>
        <li>
          <a href="http://nss.cusat.ac.in/" target="blank">National Service Scheme</a>
        </li>
        <li>
          <a href="https://welfarecusat.in/" target="blank">Youth Welfare</a>
        </li>
        <li>
          <a href="http://ugcsct.cusat.ac.in/" target="blank">Equal Opportunity Cell</a>
        </li>
      </ul>
      <div>
        <ul>
          <li>
            <a>Locate Us</a>
          </li>
          <Li>
            <a>
              <iframe src="https://maps.google.com/maps?q=10.043797639405279,%2076.32791461327217&t=&z=15&ie=UTF8&iwloc=&output=embed">
                {" "}
              </iframe>
            </a>
          </Li>
        </ul>
      </div>
      <ul>
        <li>Contact Us</li>
        <li>Director</li>
        <li>School of Legal Studies</li>
        <li>Cochin University of Science and Technology</li>
        <li>Cochin University P.O.</li>
        <li>Kalamassery, Kochi</li>
        <li>Kerala- 682022</li>
        <li>India</li>
        <li>Phone : +91 0484 257 5465</li>
        <li>Mob: +91 9383445550</li>
        <li>Email : sls@cusat.ac.in; slcusat@gmail.com </li>
      </ul>
    </Links>
  );
}

const Links = Styled.div`
width:100%;
background: #E5E5E5;
display:flex;
flex-direction:row;
justify-content:space-evenly;
ul{ 
    list-style:none;
    margin-top:37px;
    margin-bottom:30px;
    &:nth-child(2){
     text-align:center;
    }
    li{
        font-family: PT Serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        color: #000000;
        margin-bottom:16px;
     
        &:first-child{
            font-family: PT Serif;
            font-style: normal;
            font-weight:700;
            font-size: 20px;
            line-height: 17px;
            color: #000000;
            margin-bottom:30px;
            }
            a{
                text-decoration: none;
                color:#000;
                :hover{
                    color:#BA090B;
                }
            }
           
    }
}
@media screen and (max-width:1200px){
    ul{
        li{
            font-size:16px;
        }
    }
}
@media screen and (max-width:1080px){
    ul{
        li{
            &:first-child{
                font-size:18px;
            }
        }
    }
}
@media screen and (max-width:940px){
    ul{
        li{
            font-size:15px;
            &:first-child{
                font-size:16px;
            }
        }
    }
}
@media screen and (max-width:880px){
    ul{
        
        li{
            font-size:14px;
            &:first-child{
                font-size:16px;
                margin-bottom: 16px;
            }
        }
    }
}
@media screen and (max-width:740px){
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
    width:100%;
    ul{ 
        margin-left:10%;
        width:61%;
        &:nth-child(2){
            text-align:left;
            }
        li{
            font-size:16px;
            margin-bottom:14px;
            &:first-child{
                font-size:18px;
                margin-bottom:18px;
            }
        }
        }
    }
}
@media screen and (max-width:590px){
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
    width:100%;
    ul{ 
        margin-left:10%;
        width:61%;
        &:nth-child(2){
            text-align:left;
            }
        li{
            font-size:14px;
            margin-bottom:14px;
            line-height: 22px;
            &:first-child{
                font-size:16px;
                margin-bottom:18px;
            }
        }
        }
    }
}  
}@media screen and (max-width:514px){
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
    width:100%;
    ul{ 
        margin-left:10%;
        width:61%;
        &:nth-child(2){
            text-align:left;
            }
        li{
            font-size:14px;
            margin-bottom:14px;
            line-height: 22px;
            &:first-child{
                font-size:16px;
                margin-bottom:18px;
            }
        }
        }
    }
}  
}
@media screen and (max-width:415px){
    display:flex;
    flex-wrap:wrap;
    justify-content:left;
    width:100%;
    ul{ 
        margin-left:10%;
        width:61%;
        &:nth-child(2){
            text-align:left;
            }
        li{
            font-size:14px;
            margin-bottom:14px;
            &:first-child{
                font-size:16px;
                margin-bottom:18px;
            }
            
        }
        }
    }
}

 `;

const Li = Styled.li`
    iframe{
        width: 350px;
        height: 300px;
        frameborder:"0";
        style:{{border:0}};
        allowfullscreen:"";
        aria-hidden:"false";
        tabindex:"0";

        @media screen and (max-width:1450px){
            width: 320px;
            height:300px;
        }
        @media screen and (max-width:1280px){
            width: 320px;
            height:300px;
        }
        @media screen and (max-width:1200px){
            width: 300px;
            height:300px;
        }
        @media screen and (max-width:1080px){
            width: 270px;
            height:290px;
        }
        @media screen and (max-width:1000px){
            width: 250px;
            height:290px;
        }
        @media screen and (max-width:940px){
            width: 200px;
            height:290px;
        }
        @media screen and (max-width:880px){
            width: 170px;
            height:270px;
        }
        @media screen and (max-width:800px){
            width: 140px;
            height:270px;
        }
        @media screen and (max-width:768px){
            width: 120px;
            height:270px;
    }
    @media screen and (max-width:740px){
        width:400px;
        height:200px;
    }
    @media(max-width:460px){
        width:400px
    }
    @media(max-width:450px){
        width:370px
    }
    @media(max-width:415px){
        width:350px
    }
    @media(max-width:399px){
        width:320px
    }
    @media(max-width:355px){
        width:300px
    }
    @media(max-width:335px){
        width:270px
    }
 
    
`;

const Map = Styled.div``;
