import React, { useState, useEffect } from "react";
import Styled from 'styled-components'
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import axios from "axios";
import './Style.css';
import { Link } from "react-router-dom";

export default function Photogallery(){

  const API = process.env.REACT_APP_API;
  const [response, setresponse] = useState([]);
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows :true,
      autoplay:true,
      autoplayTimeOut:500, 
      loop :true,
      responsive:[,
        {
          
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 475,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }; 
    useEffect(() => {
      axios
        .get(`${API}/posts/yearview/`)
        .then((res) => {
          console.log(res.data.data);
          setresponse(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
    return (
        <Div>
            <div>
                <Span>Event Gallery</Span>
                
            </div>
            
            <Slider {...settings}>
                {
                  response.map((res)=>(
                    <div ><Link to={`/allgallery/${res.id}`}>
                <SlideImage>
                  <img src={res.image} alt="FirstImage"/>
                  <h2>{res.year}</h2>
                </SlideImage>
                </Link>
                </div>
                  ))
                }
                
            </Slider>
        </Div>
    )
}
const Div = Styled.div`
.slick-prev:before, .slick-next:before{
  font-size:30px;
  color:#BB0000 !important;
  
}
.slick-slider{
  padding:0 80px;
}
    margin: 30px 0;
    a{
      text-decoration:none;
    }
    div{
       margin: 5px 0px;
      @media screen and (max-width:837px){
        margin-bottom:20px;
      }
      @media screen and (max-width:768px){
        margin-bottom:20px;
      }
    }
    @media screen and (max-width:768px){

    }
`;
const Span = Styled.span`
font-family:Oswald;
font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 36px;
color: #0A114E;
margin-left:60px;
margin-top:30px;
@media(max-width:768px){
  font-size: 28px;
}
@media(max-width:550px){
  font-size: 26px;
}
@media(max-width:411px){
  font-size: 24px;
  margin-left:-23px;
}
`;
const SlideImage = Styled.div`
    margin-left:18px;
    margin-right:18px;
    width:400px;
    height:330px;
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    @media screen and (max-width:1800px){
      width: 315px;
      height: 280px;
    }
    @media screen and (max-width:1700px){
      width: 305px;
      height: 250px;
    }
    @media screen and (max-width:1380px){
      width: 250px;
      height: 210px;
    }
    @media screen and (max-width:1280px){
      width: 235px;
      height: 199px;
    }
    @media screen and (max-width:1177px){
      width: 200px;
      height: 177px;
    }
    @media screen and (max-width:1037px){
      width: 150px;
      height:130px;
    }
    @media screen and (max-width:837px){
      width: 120px;
      height:95px;
    }
    @media screen and (max-width:768px){
      width: 250px;
      height: 230px;
    }
    @media screen and (max-width:650px){
      width:200px;
      height:170px;
    }
    @media screen and (max-width:550px){
      width:250px;
      height:230px;
    }
    @media screen and (max-width:435px){
      width:200px;
      height:180px;
    }
    @media screen and (max-width:375px){
      width:180px;
      height:158px;
    }
    @media screen and (max-width:350px){
      width:160px;
      height:160px;
    }
 img{
     display:block;
     width :100%;
     height:100%;
     object-fit:cover;

     &:hover{
      -webkit-transform: scale(1.3);
      transform: scale(1.1);
      transition: .5s ease-in-out;
         }
     }

     
     h2{
      text-decoration: none;
      color:black;
      text-align:center;
       }
 `;
