import React from "react";
import vrk_chair from "../Images/krishna_iyer_chair.svg";
import nrm_centre from "../Images/nr_madhavamenon_centre.svg";
import sap from "../Images/SAPImage.png";
import culr from "../Images/CULRIMAGE.svg";
import newsletter from "../Images/NewsLetter.svg";
import icsi from '../Images/icsi.png';
import Styled from "styled-components";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Icsi from "./Icsi";
export default function Research() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
   
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
       
        },
      },
      {
        breakpoint: 672,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  console.log(w, "width");
  return (
    <Main>
      <H1>Research & Publications</H1>
      {/* {w < 960 ? ( */}
        <Slider {...settings}>
          <Divs>
            <Link to="/krishnaIyer">
              <div className="border">
                <Divs1>
                  <img src={vrk_chair} alt="vrk_chair" />
                </Divs1>
                <a href="#">VR Krishna Iyer Chair</a>
              </div>
            </Link>
          </Divs>
          <Divs>
            <Link to="/nrmcentre">
              <div className="border">
                <Divs1>
                  <img src={nrm_centre} alt="nrm_centre" />
                </Divs1>
                <a href="#">NR Madhava Menon Centre</a>
              </div>
            </Link>
          </Divs>
          <Divs>
            <Link to="/sap">
              <div className="border">
                <Divs1>
                  <img src={sap} alt="sap" />
                </Divs1>
                <a href="#">Special Assistance Program (SAP)</a>
              </div>
            </Link>
          </Divs>
          <Divs>
            <Link to="/culr">
              <div className="border">
                <Divs1>
                  <img src={culr} alt="culr" />
                </Divs1>
                <a href="#">CULR</a>
              </div>
            </Link>
          </Divs>
          <Divs>
            <Link to="/optimum">
            <div className="border">
                <Divs1>
                  <img src={newsletter} alt="culr" />
                </Divs1>
                <a href="#">Optimus Omnium</a>
                </div>
            </Link>
          </Divs>
          <Divs>
            <Link to="/icsi">
            <div className="border">
                <Divs1>
                  <img src={icsi} alt="culr" />
                </Divs1>
                <a href="#">Cusat-ICSI Academic Collaberation</a>
                </div>
            </Link>
          </Divs>
        </Slider>
      {/* ) : (
        <DIVSECTION>
          <UL>
            <li>
              <Link to="/sap">
             
                  <div className="box">
                    <img src={sap} alt="sap" />
                    <a href="#">UGC SAP </a>
                  </div>
                 
               
              </Link>
            </li>
            <li>
              <Link to="/krishnaIyer">
                
                  <div className="box">
                    <img src={vrk_chair} alt="vrk_chair" />
                    <a href="#">Justice V. R. Krishna Iyer Chair</a>
                  </div>
                  
               
              </Link>
            </li>
            <li>
              <Link to="/nrmcentre">
               
                  <div className="box">
                    <img src={nrm_centre} alt="nrm_centre" />
                    <a href="#">Prof. (Dr.) N. R. Madhava Menon Centre </a>
                  </div>
                  
              
              </Link>
            </li>
            <li>
              <Link to="/culr">
                <div className="border">
                  <div className="box">
                    <img src={culr} alt="culr" />
                    <a href="#">Cochin University of Law Review</a>
                  </div>
                 
                </div>
              </Link>
            </li>
            <li>
              <Link to="/optimum">
                  <div className="box">
                    <img src={newsletter} alt="culr" />
                    <a href="#">Optimus Omnium</a>
                  </div>
                  </Link>
            </li>
            <li>
              <Link to="/icsi">
                  <div className="box">
                    <img src={icsi} alt="culr" />
                    <a href="#">Cusat-ICSI Academic Collaberation</a>
                  </div>
                  </Link>
            </li>
          </UL>
        </DIVSECTION>
      )} */}
    </Main>
  );
}
const Main = Styled.div`
background: #FFF;
.slick-prev:before, .slick-next:before{
  font-size:30px;
  color:#BB0000 !important;
  
}
.slick-slider{
  padding:0 30px;
}
`;

const DIVSECTION = Styled.div`
    
    height: 260px;
    width:100%;
    margin:0 auto;
    @media screen and (max-width:1400px){
      height: 360px;
    }
    @media screen and (max-width:1280px){
      height: 330px;
    }
    @media screen and (max-width:1080px){
        height:300px;
    }
    @media screen and (max-width:960px){
      height:260px;
  }
    
`;
const H1 = Styled.h1`
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #0A114E;
    display: flex;
    margin:0 auto; 
    margin-left: 60px;
    padding-top: 36px;
    @media screen and (max-width:1080px){
      font-size: 28px;
    }
    @media screen and (max-width:768px){
      font-size: 28px;
    }
    @media screen and (max-width:550px){
      font-size: 26px;
    }
    @media screen and (max-width:411px){
      font-size: 24px;
      margin-left:20px;
    }
     
`;
const UL = Styled.ul`
 
    display: flex;
    flex-direction:row;
    justify-content: space-evenly;
    margin-top: 40px;
    li{
        list-style:none;
        text-align: center; 
    }

    img {
        width: 100%;
        height: 100%; 
        border: 7px solid #0A114E;

        :hover  {
            -webkit-transform: scale(1.3);
            transform: scale(1.1);
            transition: .3s ease-in-out;
            border: 7px solid #E01E38; 

            }
        }
    a {
        width: 300px;
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        align-items: center;
        text-align:center;
        text-decoration:none;
        color: #0A114E;
        &:hover{
            color:#E01E38;
        } 
      }
    div.box {
        display:block;
        align-items: center;
        background-color: #E5E5E5;
        width: 210px;
        height: 202px;
        color: #FFFFFF;
        border-radius: 4px 4px 4px 4px ;
        margin-bottom: 20px;
        margin-left: -20px;
      
    }
    @media screen and (max-width:1400px){
      li{
        div.box{
          width:170px;
          height:168px;
        }
      }
    }
    @media screen and (max-width:1280px){
      li{
        div.box{
          width:154px;
          height:136px;
        }
      }
    }
    @media screen and (max-width:1080px){
      li{
        div.box{
          width:140px;
          height:125px;
        }
      }
    }
    @media screen and (max-width:960px){
      li{
        div.box{
          width:140px;
          height:124px;
        }
      }
    }
    @media screen and (max-width:930px){
      li{
        div.border {
          width: 120px;
          height: 180px;
         
      }
      }
    }`;

const Divs1 = Styled.div`display:flex;
justify-content:center;
margin-top: 31px;

img{
    height: 202px;
   width: 70%;
 
   @media(max-width:375px){
    width: 70%;
   }
   


}`;
const Divs = Styled.div`
a{
  width: 300px;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  align-items: center;
  text-decoration:none;
  color: #0A114E;
  text-align :center;
  &:hover{
      color:#E01E38;
  } 
}`;
const Divs2 = Styled.div` 
`;
