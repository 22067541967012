import React, { useEffect } from "react";
import Styled from "styled-components";
import Sapimg from "../Images/SAPImage.png";

export default function Sap() {
  useEffect(() => {
    window.scroll(0,0)
  }, []);
  return (
    <DIV>
      <BOX>
        <h1>
          UGC Special Assistance Programme on Corporate Governance and Securities
          Law
        </h1>

        <COLOR>
          <TOP>
           
            <p className="above">
              UGC SAP Scheme was initiated in 1963 by University Grants
              Commission keeping in view the recommendations of the Education
              Commission to facilitate selected number of University Departments
              having some potential in research and teaching. The programme is
              intended to encourage the pursuit of excellence and team work in
              advanced teaching and research to accelerate the realization of
              international standards in specific fields. The major objectives
              of Special Assistance Programme is to identify and support
              university departments which have the potential to undertake
              quality teaching and research in various educational disciplines
              including allied disciplines, and to promulgate research in
              universities to be relevant to societal needs and have society and
              industry interaction.
            </p>
            <p className="below">
              The first Phase of the DRS-SAP-I programme on Corporate Governance
              and Securities Law of the School of legal Studies focused on
              developing necessary man power in handling intricate areas of
              Corporate Governance and Securities Law. Phase I was implemented
              during the period 2011 to 2016. On the successful completion of
              the first phase, UGC sanctioned the Phase II of UGC SAP for a
              further period of five years from 1/4/2018 to 31/3/2013. The
              second phase focuses on research and extension programmes. Apart
              from running academic programmes on core area, seminars and
              workshops would be organised to disseminate knowledge generated
              from research. It is also proposed to establish linkages with
              other research organizations in the area like Indian Institute of
              Corporate Affairs, National Institute for Securities Markets and
              so on. UGC-SAP DRS-Phase-II was inaugurated on 1stAugust 2018 by
              Prof. (Dr.) J. Letha, Honourable Vice Chancellor, CUSAT and was
              followed by a special lecture on “Ethical Aspects of Corporate
              Governance” by Mrs. Sareena P.U, Assistant General Manager,
              Securities and Exchange Board of India, Cochin.
            </p>
          </TOP>

          <H3>Coordinator:</H3> <H4>Dr. Seema P. S., Associate Professor, SLS</H4>
          <H3>Deputy Coordinator:</H3>  <H4>Dr. Preetha. S., Assistant Professor, SLS</H4>
          <H3>Some of the important activities</H3>
          <ul>
            <li>
              Three day UGC sponsored National Seminar was organized on
              “Corporate Frauds: The Regulatory Challenges and Legal Remedies”
              during 17th, 18th and 19th January, 2019.
            </li>
            <li>
              Organized Teachers Training Programme on “Contemporary
              Developments in Corporate Law”
            </li>
            <li>
              National Seminar was organized on “Changing Paradigms of Corporate
              Governance” on 14th and 15th February, 2020.
            </li>
          </ul>
          <H3>Special Lectures Organised </H3>
          <ul>
            <li>
              Dr. Fincy Pallissery, Assistant Professor, School of Law, Christ
              University, Bangalore, on the topic “Accountability of Board of
              Directors in promoter controlled public listed companies:
              Corporate Governance Perspectives” on 11-03-2019.
            </li>
            <li>
              Special Lecture by Dr. P. Syamjith, Chief Manager, State Bank of
              India, Ahmadabad on “Insolvency and Bankruptcy Code” was organized
              on 21 -11-2018.{" "}
            </li>
            <li>
              Special Lecture on “Valuation aspects of Mergers and Acquisitions”
              by Mr. Raju. P.K., Chartered Accountant and Insolvency
              Professional, Cochin on 17-12- 2018.
            </li>
            <li>
              Special Lecture by Ms. Sareena P.U, Assistant General Manager,
              SEBI on “Basics of Securities Market” on 19-03-2019.
            </li>
          </ul>
          <H3>SEBI Visit:</H3>
          <ul>
            <li>
              A visit was made to SEBI office on 08-10-2018 with a batch of
              students (46 nos.) undergoing BB.A LL.B and B.Com LL.B. A
              practical training class on “Trading in Securities” was held at
              SEBI office.
            </li>
          </ul>
          <a style={{marginTop:"14px",display:"block"}} href="/downloads">Downloads</a>
        </COLOR>
      </BOX>
    </DIV>
  );
}
const H3 = Styled.h3`
    font-family: PT Serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
    @media (max-width:395px){
      font-size: 14px;
    }
   `;
   const H4 = Styled.h4`
   font-family: PT Serif;
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   line-height: 16px;
   color: #000000;
   @media (max-width:640px){
    font-size: 18px;
  }
  @media (max-width:540px){
    font-size: 16px;
  }
  @media (max-width:395px){
    font-size: 14px;
  }`
const DIV = Styled.div`

background: #FAFAFA;
display:flex ;
justify-content:center;
align-items: center;
width:100%;
`;

const BOX = Styled.div`

width:85%;
margin:50px auto;
h1{
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 36px;
  font-weight:500;
  color: #E01E38;
  display:flex;
 }
 @media (max-width:1132px){
 h1{
  font-size: 32px;
  }
}
@media (max-width:1065px){
  h1{
   font-size: 31px;
   }
 }
 @media (max-width:534px){
  h1{
   font-size: 28px;
   }
 }
 @media (max-width:395px){
  padding: 16px;
  h1{
   font-size: 26px;
   }
 }
li{
    margin-left:50px;
    text-align:justify;
    font-size:20px;
    line-height:24px;
    font-family: PT Serif;
   
}
@media (max-width:640px){
   li{
    font-size:18px;
   }
}
@media (max-width:534px){
  li{
    font-size:16px;
  }
 }
 @media (max-width:395px){
  li{
    font-size:14px;
  }
 }
`;
const COLOR = Styled.div`
background:white;
padding:20px 40px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const TOP = Styled.div`
@media (max-width:530px){
  display:flex;
  flex-direction:column;
  align-items:center;
}
 p.above{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
  }
 p.below{
    font-family: PT Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align:justify;
    @media (max-width:640px){
      font-size: 18px;
    }
    @media (max-width:540px){
      font-size: 16px;
    }
 }


`;
